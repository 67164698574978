import React, { useEffect } from "react";
import { confetti } from "@tsparticles/confetti";

const ConfettiComponent = ({ endTime = 10, setShowConfetti, set = false }) => {
    useEffect(() => {
        const end = Date.now() + endTime * 1000;
        const colors = ["#FF5733", "#FFBD33", "#33FF57", "#337EFF", "#B333FF"];
        const frame = () => {
            confetti(
                Object.assign(
                    {},
                    {
                        particleCount: 2,
                        angle: 60,
                        spread: 55,
                        origin: { x: 0 },
                        colors: colors,
                        zIndex: 2147483647,
                    }
                )
            );

            confetti(
                Object.assign(
                    {},
                    {
                        particleCount: 2,
                        angle: 120,
                        spread: 55,
                        origin: { x: 1 },
                        colors: colors,
                        zIndex: 2147483647,
                    }
                )
            );

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        };

        frame(); // Start the animation

        // Clean up function to stop the animation when the component unmounts
        return () => {
            // Clear the timeout to stop the animation when the component unmounts
            cancelAnimationFrame(frame);
            if (set) setShowConfetti(false);
        };
    }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

    // This component doesn't render anything, it just triggers the confetti animation
    return <></>;
};

export default ConfettiComponent;
