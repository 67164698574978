export const GET_ALL_ASSISTANTS = "/assistant/getAllAssistants"
export const CREATE_ASSISTANT = "/assistant/create"
export const UPDATE_ASSISTANT = "/assistant/edit"
export const DELETE_ASSISTANT = "/assistant/delete"
export const GET_ASSISTANT = "/assistant/getAssistant"
export const ATTACH_KNOWLEDGE_BASE = "/knowledge-base/attachToAssistant"
export const TRAIN_ASSISTANT = "/assistant/training"
export const GET_SAMPLE_AUDIO = "/assistant/getSampleAudioData"
export const GET_ALL_ACTIONS = "/action/getAllActions"
export const POST_CREATE_ACTION = "/action/create"
export const DELETE_ACTION = "/action/delete"
export const EDIT_ACTION = "/action/edit"
export const GET_LINKEDIN_DATA = "/getLinkedInDetails"
export const POST_GATHER_DETAILS = "/assistant/gatherDetailsAI"
export const POST_CREATE_SCRIPT_WITH_AI = "/assistant/createScriptWithAI"
export const GET_MENTION_LIST = "/contact-list/getWorkspaceContactListCols"