import * as React from 'react';
import Popover from '@mui/material/Popover';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const CustomHintPopover = ({
    icon = <InfoOutlinedIcon fontSize='inherit' />,
    hintTitle,
    showIcon = true,
    hintContent,
    handleOnClick = () => { },
    anchorOrigin,
    transformOrigin,
    transformHorizontalPosition = 'right',
    transformVerticalPosition = 'top',
    anchorHorizontalPosition = 'right',
    anchorVerticalPosition = 'top',
    maxWidth = 1200,
    severity = 'info',
    size = 'medium',
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <div>
            <IconButton
                onClick={handleOnClick}
                color='inherit'
                size={size}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}>
                {icon}
            </IconButton>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                slotProps={{
                    paper: {
                        variant: 'elevation',
                        elevation: 4,
                    }
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={anchorOrigin || {
                    vertical: anchorVerticalPosition,
                    horizontal: anchorHorizontalPosition,
                }}
                transformOrigin={transformOrigin || {
                    vertical: transformVerticalPosition,
                    horizontal: transformHorizontalPosition,
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Alert icon={showIcon} severity={severity} sx={{
                    maxWidth: maxWidth,
                }}>
                    <AlertTitle>{hintTitle}</AlertTitle>
                    {hintContent}
                </Alert>
            </Popover>
        </div>
    );
}

export default CustomHintPopover;