import { styled } from "@mui/material/styles"
import Accordion from "@mui/material/Accordion"

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  borderRadius: theme.spacing(0.5),
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))

export default StyledAccordion
