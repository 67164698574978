import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { enqueueSnackbar } from "notistack"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import LoadingButton from "@mui/lab/LoadingButton"
import Typography from "@mui/material/Typography"
import HomeIcon from "@mui/icons-material/Home"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

import {
  updateAuthState,
  placeDemoCall,
  updateUserPhoneNumber,
} from "../../data/store/authSlice"
import { ROUTE_PATH } from "../../data/configs/constants"
import { log } from "../../data/configs/utils"
import ConfettiComponent from "../ConfettiAnimation"
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'

const TestAssistantForm = ({ assistantId, onCancel }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const existingNumber = useSelector(
    (state) => state.authDetails.user?.phoneNumber
  )
  const [phoneNumber, setPhoneNumber] = useState(existingNumber ?? "")
  const [phoneNumberInput, setPhoneNumberInput] = useState("")
  const [isDemoSuccessFul, setIsDemoSuccessFul] = useState(false)
  const modalLoading = useSelector((state) => state.pageDetails.modalLoading)
  const [countdown, setCountdown] = useState(10);
  const handleChangeNumber = () => {
    setPhoneNumber("")
  }

  const handleSubmitNumber = () => {
    setPhoneNumber(phoneNumberInput);

    dispatch(
      updateUserPhoneNumber({ phoneNumber: phoneNumberInput })
    )
  }

  const handleDemoCall = () => {
    if (
      window.location.pathname.includes(ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT)
    ) {
      setIsDemoSuccessFul(true)
    }
    dispatch(
      placeDemoCall({
        number: phoneNumber,
        assistantId,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.data && res.data.quotaExhausted === true) {
          navigate(ROUTE_PATH.PRICING + "?quotaExhausted=true")
        }
        enqueueSnackbar(
          res?.message ? res.message : "Your will recieve a call shortly",
          { variant: "success" }
        )
        if (res?.data?.user?.userId)
          dispatch(
            updateAuthState({
              user: res.data.user,
            })
          )
        if (
          window.location.pathname.includes(
            ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT
          )
        ) {
          setIsDemoSuccessFul(true)
        } else if (onCancel) onCancel()
      })
      .catch((err) => {
        log("ERR:placeDemoCall: ", err)
      })
  }

  useEffect(() => {
    let timeoutId;
    let intervalId;

    // Check if the demo is successful and start the timeout
    if (isDemoSuccessFul) {
      timeoutId = setTimeout((e) => {
        navigate(ROUTE_PATH.ASSISTANTS);
      }, 10000); // 10 seconds timeout

      // Start the interval to update the countdown every second
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    // Clear the timeout and interval if the component is unmounted or if "Go Home" is clicked
    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, [isDemoSuccessFul, navigate]);


  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        sx={{ position: "absolute", top: 8, right: 8 }}
        onClick={(e) => isDemoSuccessFul ? navigate(ROUTE_PATH.ASSISTANTS) : onCancel(e)}
      >
        <CloseIcon />
      </IconButton>
      {
        isDemoSuccessFul ? (

          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h4" fontWeight="fontWeightMedium" mb={2}>
              <Typography
                variant="inherit"
                component="span"
                color="primary.main"
              // sx={{
              //   backgroundImage: (theme) =>
              //     `-webkit-linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              //   WebkitBackgroundClip: "text",
              //   WebkitTextFillColor: "transparent",
              // }}
              >
                Congratulations
              </Typography>
              <Typography
                variant="inherit"
                component="span"
                ml={1}
                sx={{
                  animationName: "wave",
                  animationDuration: "3s",
                  animationIterationCount: "infinite",
                  transformOrigin: "70% 70%",
                  display: "inline-block",
                  "@keyframes wave": {
                    "0%": { transform: "rotate( 0.0deg)" },
                    "10%": { transform: "rotate(20.0deg)" },
                    "20%": { transform: "rotate(-10.0deg)" },
                    "30%": { transform: "rotate(20.0deg)" },
                    "40%": { transform: "rotate(-10.0deg)" },
                    "50%": { transform: "rotate(10.0deg)" },
                    "60%": { transform: "rotate( 5.0deg)" },
                    "100%": { transform: "rotate( 0.0deg)" },
                  },
                }}
              >
                😍
              </Typography>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Your Onboarding has been successfully completed.
            </Typography>
            <Typography variant="body1" color="text.secondary">
              You will shortly recieve a call.
            </Typography>
            <Typography mb={2} sx={{ maxWidth: 300 }} align="center">
              Enjoy our Free Trial with <strong>30 minutes</strong> of <strong>free voice call minutes</strong> to test our product before subscribing
            </Typography>
            <ConfettiComponent />
            <Button
              variant="contained"
              startIcon={<HomeIcon />}
              onClick={(e) => { e.preventDefault(); e.stopPropagation(); navigate(ROUTE_PATH.ASSISTANTS); }}
            >
              Go Home
            </Button>
            {isDemoSuccessFul && (
              <Typography variant="body1" color="text.secondary">
                Taking you Home in : {countdown}s
              </Typography>
            )
            }
          </Box >
        ) : (
          <>
            <Typography variant="h6" fontWeight="fontWeightMedium" mb={2}>
              <Typography
                variant="inherit"
                component="span"
                color='primary.main'
              // sx={{
              //   backgroundImage: (theme) =>
              //     `-webkit-linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              //   WebkitBackgroundClip: "text",
              //   WebkitTextFillColor: "transparent",
              // }}
              >
                Talk to Your Assistant
              </Typography>
              <Typography
                variant="inherit"
                component="span"
                ml={1}
                sx={{
                  animationName: "wave",
                  animationDuration: "3s",
                  animationIterationCount: "infinite",
                  transformOrigin: "70% 70%",
                  display: "inline-block",
                  "@keyframes wave": {
                    "0%": { transform: "rotate( 0.0deg)" },
                    "10%": { transform: "rotate(20.0deg)" },
                    "20%": { transform: "rotate(-10.0deg)" },
                    "30%": { transform: "rotate(20.0deg)" },
                    "40%": { transform: "rotate(-10.0deg)" },
                    "50%": { transform: "rotate(10.0deg)" },
                    "60%": { transform: "rotate( 5.0deg)" },
                    "100%": { transform: "rotate( 0.0deg)" },
                  },
                }}
              >
                🎉
              </Typography>
            </Typography>
            {phoneNumber ? (
              <Typography>
                Congratulations! Your phone number {phoneNumber} has been
                successfully verified. Please click the button below to initiate a
                demo call. You will shortly recieve a demo call from your
                assistant.
              </Typography>
            ) : (
              <>
                <Typography>Phone Number</Typography>
                <Typography
                  fontSize="caption.fontSize"
                  color="text.secondary"
                  mb={0.5}
                >
                  Enter your phone number, we will send a demo call to you from
                  your assistant.
                </Typography>
                <PhoneInput
                  placeholder="Enter your phone number"
                  enableSearch
                  disableSearchIcon
                  country={'us'}
                  variant="outlined"
                  size="small"
                  onChange={(ph) => setPhoneNumberInput(ph)}
                  inputStyle={{
                    width: '100%'
                  }}
                  dropdownStyle={{ alignItems: 'center', width: '1400%' }}
                  inputProps={{
                    required: true,
                    autoFocus: true
                  }}
                />
              </>
            )}

            <Box display="flex" justifyContent="center" gap={2} pt={2}>
              {phoneNumber ? (
                <>
                  <Button
                    variant="outlined"
                    sx={{ minWidth: 100 }}
                    onClick={handleChangeNumber}
                    disableElevation
                  >
                    Change Number
                  </Button>

                  <LoadingButton
                    variant="contained"
                    sx={{ minWidth: 100 }}
                    loading={modalLoading}
                    disabled={modalLoading}
                    onClick={handleDemoCall}
                  >
                    Place Demo Call
                  </LoadingButton>
                </>
              ) : (
                <LoadingButton
                  variant="contained"
                  sx={{ minWidth: 100 }}
                  loading={modalLoading}
                  disabled={modalLoading}
                  onClick={handleSubmitNumber}
                >
                  Save Number
                </LoadingButton>
              )
              }
            </Box>
          </>
        )}
    </div>
  )
}

TestAssistantForm.propTypes = {
  data: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}

TestAssistantForm.defaultProps = {
  data: {},
}

export default TestAssistantForm
