import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Typography from "@mui/material/Typography"
import MenuItem from "@mui/material/MenuItem"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import ListSubheader from "@mui/material/ListSubheader"
import LoadingButton from "@mui/lab/LoadingButton"
import CloseIcon from "@mui/icons-material/Close"

import { LANG_CODES } from "../../data/configs/constants"
import { getSampleAudio } from "../../data/store/assistantsSlice"
import { log } from "../../data/configs/utils"

const VoiceAccent = ({ data, onClose, onSave }) => {
  const dispatch = useDispatch()
  const modalLoading = useSelector((state) => state.pageDetails.modalLoading)
  const [updatedData, setUpdatedData] = useState(data)
  const [sampleAudioUrl, setSampleAudioUrl] = useState(null)

  useEffect(() => {
    dispatch(
      getSampleAudio({
        call_id: "test",
        resp_data: {
          answer: "Hello I am amy from voice genie How can i help you?",
        },
        brand_data: {
          brand_name: "voicegenie saas",
          ssml_data: data,
        },
      })
    )
      .unwrap()
      .then((res) => {
        if (res?.data?.data?.s3AnswerUrl) {
          setSampleAudioUrl(res?.data?.data?.s3AnswerUrl)
        }
      })
      .catch((err) => {
        log("ERR-getSampleAudio", err)
      })
  }, [dispatch, data])

  const handleInputChange = (value) => {
    if (value) {
      const ssml_data = Object.values(LANG_CODES)
        .flat()
        .find((item) => item.label === value)

      dispatch(
        getSampleAudio({
          call_id: "test",
          resp_data: {
            answer: "Hello I am amy from voice genie How can i help you?",
          },
          brand_data: {
            brand_name: "voicegenie-saas",
            ssml_data,
          },
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.data?.data?.s3AnswerUrl) {
            setSampleAudioUrl(res.data.data.s3AnswerUrl)
            setUpdatedData(ssml_data)
          }
        })
        .catch((err) => {
          log("ERR-getSampleAudio", err)
        })
    }
  }

  const handleSave = () => {
    onSave({ ssml_data: updatedData })
    onClose()
  }

  return (
    <Box position="relative">
      <IconButton
        sx={{ position: "absolute", top: -8, right: -8 }}
        onClick={onClose}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
      <Typography
        variant="h6"
        mb={2}
        sx={{
          backgroundImage: (theme) =>
            `-webkit-linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        Voice Customization
      </Typography>

      <Typography variant="subtitle2" mb={0.5}>
        Change Accent
      </Typography>
      <FormControl sx={{ mb: 1.5 }} fullWidth>
        <Select
          size="small"
          value={updatedData.label}
          onChange={(e) => handleInputChange(e.target.value)}
          MenuProps={{
            sx: { maxHeight: 400 },
          }}
          displayEmpty
          required
        >
          <ListSubheader
            sx={{
              borderBottom: 1,
              borderTop: 1,
              borderColor: "divider",
              lineHeight: "40px",
            }}
          >
            AMERICAN MALE
          </ListSubheader>
          {LANG_CODES.AMERICAN_MALE.map((item) => {
            return (
              <MenuItem key={item.label} value={item.label}>
                {item.label}
              </MenuItem>
            )
          })}
          <ListSubheader
            sx={{
              borderBottom: 1,
              borderTop: 1,
              borderColor: "divider",
              lineHeight: "40px",
            }}
          >
            AMERICAN FEMALE
          </ListSubheader>
          {LANG_CODES.AMERICAN_FEMALE.map((item) => {
            return (
              <MenuItem key={item.label} value={item.label}>
                {item.label}
              </MenuItem>
            )
          })}
          <ListSubheader
            sx={{
              borderBottom: 1,
              borderTop: 1,
              borderColor: "divider",
              lineHeight: "40px",
            }}
          >
            BRITISH MALE
          </ListSubheader>
          {LANG_CODES.BRTISH_MALE.map((item) => {
            return (
              <MenuItem key={item.label} value={item.label}>
                {item.label}
              </MenuItem>
            )
          })}
          <ListSubheader
            sx={{
              borderBottom: 1,
              borderTop: 1,
              borderColor: "divider",
              lineHeight: "40px",
            }}
          >
            BRITISH FEMALE
          </ListSubheader>
          {LANG_CODES.BRITISH_FEMALE.map((item) => {
            return (
              <MenuItem key={item.label} value={item.label}>
                {item.label}
              </MenuItem>
            )
          })}
          <ListSubheader
            sx={{
              borderBottom: 1,
              borderTop: 1,
              borderColor: "divider",
              lineHeight: "40px",
            }}
          >
            CUSTOM
          </ListSubheader>
          {LANG_CODES.CUSTOM_ACCENT.map((item) => {
            return (
              <MenuItem key={item.label} value={item.label}>
                {item.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>

      <Typography variant="subtitle2" fontWeight="fontWeightMedium" mb={0.5}>
        Selected Voice Accent
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        border={1}
        borderColor="divider"
        borderRadius={2}
        p={1}
        mb={1}
      >
        <Typography color="primary">{updatedData.label}</Typography>
        {sampleAudioUrl && (
          <Box
            component="audio"
            controls
            flex={1}
            width="100%"
            src={sampleAudioUrl}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="center" gap={2} pt={2}>
        <Button
          variant="outlined"
          sx={{ minWidth: 100 }}
          onClick={onClose}
          disableElevation
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          sx={{ minWidth: 100 }}
          loading={modalLoading}
          disabled={modalLoading}
          onClick={handleSave}
        >
          Save
        </LoadingButton>
      </Box>
    </Box>
  )
}

VoiceAccent.defaultValue = {
  data: {
    label: "Ava",
    lang: "en",
    country: "US",
    gender: "FEMALE",
    vtype: "Neural2",
    model: "G",
    speech_rate: 1,
    loudness: 0,
  },
}

export default VoiceAccent
