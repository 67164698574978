const initialState = {
  campaigns: [],
  loading: false,
  loadError: false,
  hasMore: false,
  searchText: "",
  showWarningDialog: false,

  // campaign overview
  selectedCampaign: null,
  campaignCallData: [],
  activeCallData: [],
  analyticsData: [],
  overviewLoading: false,
  overviewLoadError: true,
  overviewPage: 0,
  overviewRowsPerPage: 10,
  overviewTotalCount: 0,
}

export default initialState
