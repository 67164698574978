import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { enqueueSnackbar } from "notistack"

import IMG_GOOGLE_ICON from "../../data/assets/img/googleIcon.svg"
import { LOCAL_STORAGE } from "../../data/configs/constants"
import { setDataInLocalStorage } from "../../data/configs/utils"

const LoginWithGoogle = (props) => {
  
  const handleLoginWithGoogle = () => {
    if (process.env.REACT_APP_GOOGLE_AUTH_URL){
      setDataInLocalStorage(LOCAL_STORAGE.AUTH_SEARCH_PARAMS, window.location.search);
      window.open(process.env.REACT_APP_GOOGLE_AUTH_URL, "_self")
    }
    else enqueueSnackbar("Missing Google Auth URL", { variant: "error" })
  }

  return (
    <Button
      variant="outlined"
      onClick={handleLoginWithGoogle}  
      {...props}
    >
      <Box
        component="img"
        src={IMG_GOOGLE_ICON}
        width={24}
        mr={1}
        alt="login-with-google"
      />
      Continue with google
    </Button>
  )
}

export default LoginWithGoogle
