import React, { useState, useEffect, useRef } from 'react';
import { Grid, TextField, Button, Typography, Paper, Box } from '@mui/material';
import { postGatherDetails } from '../../../../../../data/store/assistantsSlice';
import { useDispatch, useSelector } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import { THEME_MODE } from "../../../../../../data/configs/constants"
import theme from '../../../../../../data/theme/light';
import BoltIcon from '@mui/icons-material/Bolt';
import chatbotLogo from "../../../../../../data/assets/img/chatbot_logo.png"
import { debounced, handleCleverTapBtnClick } from '../../../../../../data/configs/utils';
const dotTypingStyles = {
    dotTyping: {
        position: 'relative',
        left: '-9999px',
        width: '10px',
        height: '10px',
        borderRadius: '5px',
        backgroundColor: '#9880ff',
        color: '#9880ff',
        boxShadow: '9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff',
        animation: 'dot-typing 1.5s infinite linear',
    },
    '@keyframes dot-typing': {
        '0%': {
            boxShadow: '9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff',
        },
        '100%': {
            boxShadow: '9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff',
        },
    },
};

const StreamingText = ({ text, speed = 100 }) => {
    // Remove double quotes from the start and end of the text
    const cleanedText = text.replace(/^"|"$/g, '').split(" ");

    const [streamingText, setStreamingText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0)
    useEffect(() => {
        let intervalId = setInterval(() => {
            setCurrentIndex((prevState) => {
                if (prevState >= cleanedText.length - 1) {
                    // need to stop the currectindex from increasing
                    clearInterval(intervalId);
                    return prevState;
                }
                return prevState + 1;
            });
        }, speed);

        return () => clearInterval(intervalId);
    }, [cleanedText, speed]);
    useEffect(() => {
        setStreamingText((prevText) => {
            return prevText + " " + cleanedText[currentIndex];
        });
    }, [currentIndex]);
    return <span>{streamingText || ' '}</span>;
};


const ChatBot = ({ workspaceId, backendChat, setBackendChat, businessName, businessDescription, handleNext, setActiveStep }) => {
    const [isNextEnable, setIsNextEnable] = useState(true);
    const textareaRef = useRef(null);
    const [currentMessage, setMessage] = useState('');
    const [chat, setChat] = useState([]);
    const [continueChat, setContinueChat] = useState(false);
    const [generateAI, setGenerateAI] = useState(false);
    const [generateAIOption, setGenerateAIOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userTyping, setUserTyping] = useState(false);
    const dispatch = useDispatch();
    const [tempChatOne, setTempChatOne] = useState('');
    const themeMode = useSelector((state) => state.pageDetails.themeMode)
    useEffect(() => {
        const initialBotMessage = {
            sender: 'bot',
            text: "What kind of personality do you want to give to your assistant?",
        };
        setChat([initialBotMessage]);
        setContinueChat(false);
    }, []);
    useEffect(() => {
        if (backendChat.length === 10) {
            setGenerateAI(true)
        }
        if (backendChat.length === 4) {
            setGenerateAIOpen(true);
        }
        if (backendChat.length === 4) {
            setTempChatOne(chat[chat.length - 1].text); // Set tempChatOne after bot's message
        }
    }, [backendChat]);

    const userTypingDebounced = debounced(() => { setUserTyping(false) }, 500)

    const handleKeyDown = (e) => {
        if (!userTyping) setUserTyping(true)
        userTypingDebounced()
        if (e.keyCode === 13 && !e.shiftKey && isNextEnable) {
            gatherDetail();
            setUserTyping(false);
        }
    }
    const gatherDetail = () => {
        setLoading(true);
        setIsNextEnable(false);
        if (currentMessage.trim() !== '') {
            const updatedChat = [...chat, { sender: 'human', text: currentMessage.trim() }];
            setChat(updatedChat);
            setMessage('');

            const requestBody = {
                input: currentMessage.trim(),
                businessName: businessName,
                description: businessDescription,
                pastMessages: backendChat,
            };

            dispatch(postGatherDetails(requestBody))
                .unwrap()
                .then((res) => {
                    if (res && res.status === 'success' && res.data && res.data.response) {
                        const botResponse = res.data.pastMessages;
                        setBackendChat([...backendChat, botResponse]);
                        setLoading(false);
                        const newChat = [...updatedChat, { sender: 'bot', text: res.data.response }];
                        setChat(newChat);
                        setIsNextEnable(true);
                    }
                })
                .catch((err) => {
                    console.error("ERR-getAllActions", err);
                });
        }
    };

    useEffect(() => {
        // Function to scroll the textarea to the bottom
        if (textareaRef.current) {
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
    }, [backendChat, chat]);


    return (
        <Grid container spacing={2} marginTop={0} sx={{ height: '80vh' }} >
            <Grid item xs={12} sx={{ height: "80%" }}>
                <Grid item xs={12}>
                    <Typography variant='h4' align='center' component="div" pb={0}>
                        Tell us a bit more....
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='caption' align='center' component="div" pb={2}>
                        The more descriptive your answers are, the better the script will be.
                    </Typography>
                </Grid>
                <Paper variant="outlined" ref={textareaRef} style={{ height: '97%', overflowY: 'auto', padding: "15px", backgroundColor: themeMode === THEME_MODE.DARK ? "#444" : 'background.paper' }}>
                    <Grid container xs={12} style={{ width: "100%", marginBottom: 22 }}>

                        {chat.map((item, index) => (
                            <>
                                {item.sender === 'bot' ?
                                    <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '8px', alignItems: 'center' }}>
                                        <img src={chatbotLogo} alt="Alt Text" style={{ width: "30px", height: "30px", marginRight: '8px', verticalAlign: 'middle' }} />
                                        <Box style={{ backgroundColor: theme.palette.primary.main, color: "white", padding: '8px', borderRadius: '8px', }}>
                                            {
                                                <Typography variant="body1">
                                                    {index === 8 ? (
                                                        <>
                                                            {continueChat && <StreamingText text={tempChatOne} />}
                                                        </>
                                                    ) : index === 20 ? (
                                                        <>
                                                            <Typography>
                                                                Thank you!
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <StreamingText text={item.text} />
                                                    )}
                                                </Typography>
                                            }

                                        </Box>
                                    </Grid>
                                    :
                                    <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }} align="end">
                                        <Grid item sx={{ maxWidth: "70%" }}>
                                            <Box style={{ backgroundColor: theme.palette.secondary.main, padding: '8px', borderRadius: '8px' }}>
                                                <Typography variant="body1" align='start'>{item.text}</Typography>
                                            </Box>
                                        </Grid>

                                    </Grid>

                                }
                            </>
                        ))
                        }

                        {loading &&
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Box style={{ color: "white", borderRadius: '8px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img src={chatbotLogo} alt="Logo" style={{ width: "30px", height: "30px", marginRight: '22px', verticalAlign: 'middle' }} />
                                    <div className="col-3">
                                        <div className="snippet" data-title="dot-typing">
                                            <div className="stage">
                                                <div style={dotTypingStyles.dotTyping}></div>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </Grid>
                        }
                        {userTyping &&
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingInline: 20 }}>
                                <Box style={{ color: "white", borderRadius: '8px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <div className="col-3">
                                        <div className="snippet" data-title="dot-typing">
                                            <div className="stage">
                                                <div style={dotTypingStyles.dotTyping}></div>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </Grid>
                        }

                    </Grid>
                </Paper>
            </Grid>
            {
                !generateAI ? (
                    <Grid container xs={12} marginLeft={2}>
                        {
                            (backendChat.length === 4 && !continueChat ?

                                (
                                    <Grid container xs={12} spacing={2} marginTop={8}>
                                        <Grid item xs={6} align="center">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    setContinueChat(true);
                                                    handleCleverTapBtnClick("Continue Chat");
                                                }}
                                                sx={{ minWidth: "150px", padding: 1 }}
                                            >
                                                Continue Chat
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} align="center">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => { handleNext(); handleCleverTapBtnClick("Generate AI"); }}
                                                endIcon={<BoltIcon fontSize="small" />}
                                                sx={{ minWidth: "150px", padding: 1 }}
                                            >
                                                Generate AI
                                            </Button>
                                        </Grid>
                                    </Grid>

                                )
                                :
                                (
                                    <Grid container xs={12} spacing={2} marginTop={8}>
                                        <Grid item xs={generateAIOption ? 8 : 10}>
                                            <TextField
                                                label="Type a message..."
                                                value={currentMessage}
                                                onChange={(e) => setMessage(e.target.value)}
                                                onKeyDown={handleKeyDown}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={2} alignItems='center'>
                                            <Button
                                                sx={{
                                                    padding: 1.5,
                                                    '& .MuiButton-endIcon': {
                                                        marginLeft: '8px',
                                                    },
                                                }}
                                                variant="contained"
                                                color="primary"
                                                onClick={gatherDetail}
                                                fullWidth
                                                endIcon={<SendIcon fontSize="small" />}
                                                disabled={(currentMessage === '' && isNextEnable)}
                                            >
                                                Send
                                            </Button>
                                        </Grid>

                                        {
                                            generateAIOption &&
                                            <Grid item xs={2} align="center">
                                                <Button
                                                    sx={{
                                                        padding: 1.5,
                                                        '& .MuiButton-endIcon': {
                                                            marginLeft: '8px',
                                                        },
                                                        minWidth: "150px"
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        handleNext();
                                                        handleCleverTapBtnClick("Generate AI");
                                                    }}
                                                    endIcon={<BoltIcon fontSize="small" />}

                                                >
                                                    Generate AI
                                                </Button>
                                            </Grid>
                                        }
                                    </Grid>
                                )
                            )
                        }
                    </Grid>
                )
                    :
                    (
                        <Grid item xs={12} align="center" marginTop={7}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                endIcon={<BoltIcon fontSize="small" />}
                                sx={{ minWidth: "150px", padding: 1 }}
                            >
                                Generate AI
                            </Button>
                        </Grid>
                    )
            }
        </Grid >
    )

};

export default ChatBot;
