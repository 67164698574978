import React, { useState, useEffect } from "react";
import {
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Paper,
    Box,
    Card,
    Slider,
    styled,
    Switch,
    AccordionDetails,
    Accordion,
    AccordionSummary,
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
} from "@mui/material";
import ErrorBoundary from "../../../components/ErrorBoundary";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import VoiceAccent from "../../../components/VoiceAccent";
import {
    LANG_CODES,
    LANG_COUNTRY,
    MULTI_LANG_CODES,
} from "../../../data/configs/constants";
import VoiceAssistant from "./VoiceAssistant";
import CustomHintPopover from "../../CustomHintPopover";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import VoiceMail from "../../VoiceMail";

const CustomizationComponent = ({ handleChange, assistant }) => {
    const [dialogData, setDialogData] = useState({});
    const [sampleAudioUrl, setSampleAudioUrl] = useState(null);
    const [updatedData, setUpdatedData] = useState(assistant?.ssml_data);
    const [selectedLanguage, setSelectedLanguage] = useState(
        assistant?.ssml_data?.lang ?? "en"
    );
    const [selectedGender, setSelectedGender] = useState(
        assistant?.ssml_data?.gender.toLowerCase() ?? "male"
    );
    const [filteredVoices, setFilteredVoices] = useState([]);
    const themeMode = useSelector((state) => state.pageDetails.themeMode);
    const getAudioUrl = (lang, label) => {
        return `https://voicegenie-saas-public-resources.s3.ap-south-1.amazonaws.com/Assets/Accents/${label}/${lang}_${label}.mp3`;
    };
    const theme = useTheme();
    const [additionalConfigObj, setAdditionalConfigObj] = useState({
        interruption: assistant?.additionalConfig?.interruption ?? true,
        brand_name: assistant?.additionalConfig?.brand_name ?? 'deepgram'
    });

    const handleChangeInterruptionSettings = (event) => {
        const { name, value } = event.target;

        setAdditionalConfigObj((prevState) => {
            const updatedConfig = { ...prevState, [name]: value };

            handleChange({
                additionalConfig: {
                    ...assistant?.additionalConfig,
                    ...updatedConfig,
                },
            });

            return updatedConfig;
        });
    };



    const [advancedSettings, setAdvancedSettings] = useState({
        max_duration: assistant?.ssml_data?.max_duration ?? 900,
        rengage_caller: assistant?.ssml_data?.rengage_caller ?? false,
        rengage_count: assistant?.ssml_data?.rengage_count ?? 2,
        no_input: assistant?.ssml_data?.no_input ?? 15,
        speech_rate: assistant?.ssml_data?.speech_rate ?? 15,
        rengage_message:
            assistant?.ssml_data?.rengage_message ??
            `Sorry, I didn't get any response from you, are you still there?`,
    });

    const handleCloseDialoge = (e, reason) => {
        if (reason !== "backdropClick") setDialogData({});
    };

    const handleInputChange = (value) => {
        if (value && selectedGender && selectedLanguage) {
            const ssml_data = filteredVoices.find((item) => item.label === value);
            const audioUrl = getAudioUrl(ssml_data?.lang, value);
            setSampleAudioUrl(audioUrl);
            setUpdatedData({ ...ssml_data, ...advancedSettings });
            handleChange({ ssml_data: { ...ssml_data, ...advancedSettings } });
            handleChange({ gender: selectedGender });
        }
    };

    const marks = [
        {
            value: 2,
            label: "2",
        },
        {
            value: 3,
            label: "3",
        },
        {
            value: 4,
            label: "4",
        },
        {
            value: 5,
            label: "5",
        },
    ];

    const handleSaveLangChange = (lang) => {
        setUpdatedData((prev) => ({
            ...prev,
            lang,
            country: LANG_COUNTRY[lang] || "",
        }));
        handleChange({
            ssml_data: { ...updatedData, lang, country: LANG_COUNTRY[lang] || "" },
        });
    };

    const handleSelectedAccent = () => {
        const matched = filteredVoices.filter(
            (voice) => voice.label === updatedData?.label
        );
        return matched.length > 0 ? updatedData?.label : "";
    };

    const handleLangChange = (lang) => {
        setSelectedLanguage(lang);
        const newUpdatedLang = extractAndConcatenateFilterVoices(lang);
        const matched = newUpdatedLang.filter(
            (voice) => voice.label === updatedData?.label
        );
        const matchingValue = matched.length > 0 ? updatedData?.label : "";
        if (matchingValue.length > 0) {
            handleSaveLangChange(lang);
        }
    };

    const extractAndConcatenateFilterVoices = (selectedLanguage) => {
        let lang_Code_Array =
            LANG_CODES[selectedLanguage][selectedGender.toLowerCase()];
        let multi_Lang_Code_Array = MULTI_LANG_CODES.language.includes(
            selectedLanguage
        )
            ? MULTI_LANG_CODES[selectedGender.toLowerCase()]
            : [];
        let country_Code = LANG_COUNTRY[selectedLanguage] || "";
        multi_Lang_Code_Array = multi_Lang_Code_Array?.map((item) => ({
            ...item,
            lang: selectedLanguage,
            country: country_Code,
        }));
        setFilteredVoices([...lang_Code_Array, ...multi_Lang_Code_Array]);
        return [...lang_Code_Array, ...multi_Lang_Code_Array];
    };
    const handleChangeAdvanceSettings = (name, newValue) => {
        setAdvancedSettings((prevSettings) => ({
            ...prevSettings,
            [name]: newValue,
        }));
        setUpdatedData((prev) => ({
            ...prev,
            ...advancedSettings,
            [name]: newValue,
        }));
        handleChange({
            ssml_data: { ...updatedData, ...advancedSettings, [name]: newValue },
        });
    };

    useEffect(() => {
        extractAndConcatenateFilterVoices(selectedLanguage);
    }, [selectedGender]);

    useEffect(() => {
        if (updatedData?.label) {
            const audioUrl = getAudioUrl(updatedData?.lang, updatedData?.label);
            setSampleAudioUrl(audioUrl);
        }
    }, [updatedData]);

    useEffect(() => {
        if (assistant?.ssml_data) {
            setUpdatedData(assistant?.ssml_data);
        }
    }, [assistant]);

    return (
        Object.keys(assistant).length !== 0 && (
            <Grid
                container
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "space-between",
                }}
            >
                <Grid container item xs={6} pr={1}>
                    <Card
                        sx={{
                            pt: 2,
                            pb: 4,
                            boxShadow: "0px 0px 1px 0px",
                            minHeight: "450px",
                        }}
                    >
                        <Grid item xs={12} paddingInline={3} mb={2}>
                            <Typography variant="h6" fontWeight="bold" align="start">
                                Details
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            xs={12}
                            paddingInline={2}
                            sx={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    display="flex"
                                    marginLeft={1}
                                    alignItems="center"
                                    variant="subtitle1"
                                >
                                    Name :
                                    <CustomHintPopover
                                        size="small"
                                        maxWidth={400}
                                        transformHorizontalPosition={"left"}
                                        transformVerticalPosition={"center"}
                                        anchorHorizontalPosition={"right"}
                                        anchorVerticalPosition={"top"}
                                        hintContent={
                                            "Choose a name that suits the personality of your assistant. The assistant would call themself by this name during a conversation."
                                        }
                                    />
                                </Typography>
                                <TextField
                                    placeholder="eg : Steffan"
                                    value={assistant?.name}
                                    onChange={(e) => handleChange({ name: e.target.value })}
                                    fullWidth
                                    sx={{ marginTop: 0.5, marginBottom: 2 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    display="flex"
                                    marginLeft={1}
                                    alignItems="center"
                                    variant="subtitle1"
                                >
                                    Company :
                                    <CustomHintPopover
                                        size="small"
                                        maxWidth={400}
                                        transformHorizontalPosition={"left"}
                                        transformVerticalPosition={"center"}
                                        anchorHorizontalPosition={"right"}
                                        anchorVerticalPosition={"top"}
                                        hintContent={
                                            "Enter the name of the company or business which the AI assistant represents."
                                        }
                                    />
                                </Typography>
                                <TextField
                                    // label="Company Name"
                                    placeholder="eg : Voicegenie"
                                    value={assistant?.companyName}
                                    onChange={(e) =>
                                        handleChange({ companyName: e.target.value })
                                    }
                                    fullWidth
                                    sx={{ marginTop: 0.5, marginBottom: 2 }}
                                />
                            </Grid>
                            <Grid item xs={12} mb={2}>
                                <Typography
                                    display="flex"
                                    marginLeft={1}
                                    alignItems="center"
                                    variant="subtitle1"
                                >
                                    Goal :
                                    <CustomHintPopover
                                        size="small"
                                        maxWidth={400}
                                        transformHorizontalPosition={"left"}
                                        transformVerticalPosition={"center"}
                                        anchorHorizontalPosition={"right"}
                                        anchorVerticalPosition={"top"}
                                        hintContent={
                                            "This will be the main objective that your AI assistant will try to achieve once its in conversation with your contacts."
                                        }
                                    />
                                </Typography>
                                <TextField
                                    value={assistant?.goal ?? ""}
                                    placeholder="eg : Lead generation"
                                    onChange={(e) => handleChange({ goal: e.target.value })}
                                    fullWidth
                                    multiline
                                    minRows={3}
                                    variant="outlined"
                                    sx={{ marginTop: 0.5 }}
                                    inputProps={{ maxLength: 1000 }}
                                />
                                {!assistant.goal?.trim() && (
                                    <Typography
                                        paddingInline={2}
                                        variant="subtitle2"
                                        color="error"
                                    >
                                        Assistant's Goal is required !
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={6} pl={1}>
                    <Paper
                        sx={{ padding: 2, boxShadow: "0px 0px 1px 0px", height: "100%" }}
                    >
                        <Grid item xs={12} paddingInline={1} mb={4}>
                            <Typography variant="h6" fontWeight="bold" align="start">
                                Voice Settings
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid container item xs={12} justifyContent="space-between">
                                    <Grid
                                        container
                                        paddingInline={1}
                                        justifyContent="space-between"
                                    >
                                        <Grid item display="flex" xs={5} alignItems="center">
                                            <Typography
                                                display="flex"
                                                alignItems="center"
                                                variant="subtitle1"
                                                align="center"
                                            >
                                                Chosen Accent :
                                                <CustomHintPopover
                                                    size="small"
                                                    maxWidth={400}
                                                    transformHorizontalPosition={"left"}
                                                    transformVerticalPosition={"center"}
                                                    anchorHorizontalPosition={"right"}
                                                    anchorVerticalPosition={"top"}
                                                    hintContent={
                                                        "Refer to the specific pronunciation patterns and speech characteristics that the AI bot adopts to sound like a native speaker in chosen language."
                                                    }
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7} display="flex" alignItems="center">
                                            <Typography variant="h6">
                                                {updatedData?.label ?? ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container marginTop={3}>
                                <Grid item xs={5} display="flex" alignItems="center" pl={1}>
                                    <Typography
                                        display="flex"
                                        alignItems="flex-end"
                                        variant="subtitle1"
                                    >
                                        Accent Sample Audio :
                                        <CustomHintPopover
                                            size="small"
                                            maxWidth={400}
                                            transformHorizontalPosition={"left"}
                                            transformVerticalPosition={"center"}
                                            anchorHorizontalPosition={"right"}
                                            anchorVerticalPosition={"top"}
                                            hintContent={"Sample audio of the chosen Accent."}
                                        />
                                    </Typography>
                                </Grid>
                                {sampleAudioUrl && (
                                    <Grid item xs={7}>
                                        <audio
                                            controls
                                            style={{ flex: 1, width: "100%" }}
                                            src={sampleAudioUrl}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                marginBlock={3}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={5.5} mb={2}>
                                    <Typography
                                        display="flex"
                                        mb={0.5}
                                        ml={1}
                                        alignItems="center"
                                        variant="subtitle1"
                                    >
                                        Language :
                                        <CustomHintPopover
                                            size="small"
                                            maxWidth={400}
                                            transformHorizontalPosition={"left"}
                                            transformVerticalPosition={"center"}
                                            anchorHorizontalPosition={"right"}
                                            anchorVerticalPosition={"top"}
                                            hintContent={
                                                "This will be the language in which the bot will interact with the contact. Note : Please keep the text of Call Opening Message of the Assistant in the same language as chosen here for best results."
                                            }
                                        />
                                    </Typography>
                                    <Select
                                        fullWidth
                                        size="small"
                                        value={selectedLanguage ?? assistant?.ssml_data?.gender}
                                        onChange={(e) => handleLangChange(e.target.value)}
                                    >
                                        <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="hi">Hindi</MenuItem>
                                        {/* <MenuItem value="ar">Arabic</MenuItem> */}
                                        <MenuItem value="fr">French</MenuItem>
                                        <MenuItem value="de">German</MenuItem>
                                        <MenuItem value="es">Spanish</MenuItem>
                                        <MenuItem value="ja">Japanese</MenuItem>
                                        <MenuItem value="pt">Portuguese</MenuItem>
                                        <MenuItem value="nl">Dutch</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item container xs={5.5} mb={2}>
                                    <Typography
                                        display="flex"
                                        mb={0.5}
                                        ml={1}
                                        alignItems="center"
                                        variant="subtitle1"
                                    >
                                        Gender :
                                        <CustomHintPopover
                                            size="small"
                                            maxWidth={400}
                                            transformHorizontalPosition={"right"}
                                            transformVerticalPosition={"center"}
                                            anchorHorizontalPosition={"right"}
                                            anchorVerticalPosition={"top"}
                                            hintContent={
                                                "Choose assistant gender that fits the personality and role of your assistant"
                                            }
                                        />
                                    </Typography>
                                    <Select
                                        fullWidth
                                        size="small"
                                        value={selectedGender}
                                        onChange={(e) => setSelectedGender(e.target.value)}
                                    >
                                        <MenuItem value="male">Male</MenuItem>
                                        <MenuItem value="female">Female</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {updatedData && (
                                    <VoiceAssistant
                                        gender={assistant?.gender}
                                        updatedData={updatedData}
                                        selectedLanguage={selectedLanguage}
                                        selectedGender={selectedGender}
                                        handleInputChange={handleInputChange}
                                        LANG_CODES={LANG_CODES}
                                        handleChange={handleChange}
                                        getAudioUrl={getAudioUrl}
                                        filteredVoices={filteredVoices}
                                        handleSelectedAccent={handleSelectedAccent}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Dialog
                            aria-labelledby="vg-voice-assistant"
                            open={dialogData.showVoiceAccent || false}
                            onClose={handleCloseDialoge}
                            fullWidth
                        >
                            <DialogContent>
                                {dialogData?.showVoiceAccent && (
                                    <ErrorBoundary onErrorCallback={handleCloseDialoge}>
                                        <VoiceAccent
                                            data={assistant.ssml_data}
                                            onClose={handleCloseDialoge}
                                            onSave={handleChange}
                                        />
                                    </ErrorBoundary>
                                )}
                            </DialogContent>
                        </Dialog>
                    </Paper>
                </Grid>

                <Grid container xs={12} mt={2}>
                    <Card
                        sx={{
                            width: "100%",
                            pt: 2,
                            pb: 4,
                            paddingInline: 2,
                            boxShadow: "0px 0px 1px 0px",
                            minHeight: "450px",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            paddingInline={3}
                            mb={3}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h6" fontWeight="bold" align="start">
                                Advanced Settings
                            </Typography>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{ minWidth: 100, textTransform: "none" }}
                                onClick={() => {
                                    const newDefaultValues = {
                                        max_duration: 900,
                                        rengage_caller: false,
                                        rengage_count: 2,
                                        no_input: 15,
                                        speech_rate: 15,
                                        rengage_message:
                                            "Sorry, I didn't get any response from you, are you still there?",
                                    };
                                    setAdvancedSettings({
                                        ...newDefaultValues,
                                    });
                                    handleChange({
                                        ssml_data: { ...updatedData, ...newDefaultValues },
                                    });
                                }}
                            >
                                Use Default Values
                            </Button>
                        </Grid>

                        <Grid
                            container
                            xs={12}
                            paddingInline={2}
                            alignItems="center"
                            sx={{ display: "flex", justifyContent: "space-between" }}
                        >
                            <Grid container item xs={12}>
                                <Grid item xs={5}>
                                    <Typography
                                        display="flex"
                                        alignItems="center"
                                        variant="subtitle1"
                                    >
                                        Max Call Duration :
                                        <CustomHintPopover
                                            size="small"
                                            maxWidth={400}
                                            transformHorizontalPosition={"left"}
                                            transformVerticalPosition={"center"}
                                            anchorHorizontalPosition={"right"}
                                            anchorVerticalPosition={"top"}
                                            hintContent={
                                                "Set a time limit for your calls. The voice assistant will end the call once this duration is reached. "
                                            }
                                        />
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        display="flex"
                                        alignItems="center"
                                        fontWeight="bold"
                                    >
                                        ({advancedSettings.max_duration / 60} minutes)
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Slider
                                        valueLabelDisplay="auto"
                                        value={advancedSettings.max_duration / 60}
                                        step={1}
                                        onChange={(e, newValue) =>
                                            handleChangeAdvanceSettings("max_duration", newValue * 60)
                                        }
                                        aria-labelledby="max-duration-slider"
                                        min={5}
                                        max={20}
                                    />
                                    <Box
                                        mb={2}
                                        sx={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <Typography variant="caption" sx={{ cursor: "pointer" }}>
                                            5
                                        </Typography>
                                        <Typography variant="caption" sx={{ cursor: "pointer" }}>
                                            20
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={5}>
                                    <Typography
                                        display="flex"
                                        alignItems="center"
                                        variant="subtitle1"
                                    >
                                        Speech Rate :
                                        <CustomHintPopover
                                            size="small"
                                            maxWidth={400}
                                            transformHorizontalPosition={"left"}
                                            transformVerticalPosition={"center"}
                                            anchorHorizontalPosition={"right"}
                                            anchorVerticalPosition={"top"}
                                            hintContent={
                                                "Adjust how fast or slow the voice assistant talks. Set it to your perfect speed for an enjoyable listening experience ! "
                                            }
                                        />
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        display="flex"
                                        alignItems="center"
                                        fontWeight="bold"
                                    >
                                        ( {advancedSettings.speech_rate} )
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Slider
                                        valueLabelDisplay="auto"
                                        value={advancedSettings.speech_rate}
                                        onChange={(e, newValue) =>
                                            handleChangeAdvanceSettings("speech_rate", newValue)
                                        }
                                        aria-labelledby="speech-rate-slider"
                                        min={-20}
                                        max={20}
                                    />
                                    <Box
                                        mb={2}
                                        sx={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <Typography variant="caption" sx={{ cursor: "pointer" }}>
                                            -20
                                        </Typography>
                                        <Typography variant="caption" sx={{ cursor: "pointer" }}>
                                            20
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={5}>
                                    <Typography
                                        display="flex"
                                        alignItems="center"
                                        variant="subtitle1"
                                    >
                                        No Response Time Limit :
                                        <CustomHintPopover
                                            size="small"
                                            maxWidth={400}
                                            transformHorizontalPosition={"left"}
                                            transformVerticalPosition={"center"}
                                            anchorHorizontalPosition={"right"}
                                            anchorVerticalPosition={"top"}
                                            hintContent={
                                                "Set how long the assistant waits for the user to respond during a call. If the user doesn't reply within this time, the call will end (unless re-engagement settings are on, in which case the assistant will attempt to re-engage with the user) . "
                                            }
                                        />
                                    </Typography>
                                    <Typography variant="subtitle2" fontWeight="bold">
                                        ( {advancedSettings.no_input} seconds)
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Slider
                                        valueLabelDisplay="auto"
                                        disabled={!advancedSettings.no_input}
                                        value={advancedSettings.no_input}
                                        onChange={(e, newValue) =>
                                            handleChangeAdvanceSettings("no_input", newValue)
                                        }
                                        aria-labelledby="no_input-slider"
                                        min={15}
                                        max={30}
                                    />
                                    <Box
                                        mb={2}
                                        sx={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <Typography variant="caption" sx={{ cursor: "pointer" }}>
                                            15
                                        </Typography>
                                        <Typography variant="caption" sx={{ cursor: "pointer" }}>
                                            30
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid container item xs={12} marginBlock={1} display='flex' alignItems='center' paddingInline={2}>
                            <Grid item xs={5}
                                alignItems="center">
                                <Typography display="flex" variant="subtitle1" alignItems='center' >
                                    Assistant's Response Behavior:
                                    <CustomHintPopover
                                        size="small"
                                        maxWidth={400}
                                        transformHorizontalPosition={"left"}
                                        transformVerticalPosition={"center"}
                                        anchorHorizontalPosition={"right"}
                                        anchorVerticalPosition={"top"}
                                        hintContent={
                                            <>
                                            <strong>
                                                    Rapid Response:
                                                </strong>
                                                <p>
                                                    Assistant will start speaking as soon as silence is detected from the caller's end, without checking if the customer has fully conveyed their message.This will have quicker responses from the assistant.
                                                </p> 
                                                <br />
                                                <strong>Thoughtful Response (Contextual Precision) :</strong>
                                                <p>
                                                    The assistant will try to respond back prioritizing what the customer is trying to say over silence detected while the caller is speaking.On an average this will make assistant's responses slower than Rapid response.
                                                </p>
                                               
                                                
                                            </>
                                        }
                                    />
                                </Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup
                                    row
                                    aria-labelledby="brandname-controlled-radio-buttons-group"
                                    name="brand_name"
                                    value={additionalConfigObj.brand_name ?? 'deepgram'}
                                    onChange={handleChangeInterruptionSettings}
                                >
                                    <FormControlLabel sx={{ minWidth: '200px' }} value="deepgram" control={<Radio />} label="Rapid Response" />
                                    <FormControlLabel sx={{ minWidth: '200px' }} value="azure" control={<Radio />} label="Thoughtful Response  (Contextual Precision)" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} marginTop={2} marginBottom={1} paddingInline={2}>
                            <Grid item xs={5} display="flex"
                                alignItems="center">
                                <Typography display="flex" variant="subtitle1" alignItems='center' >
                                    Allow Assistant Interruption :
                                    <CustomHintPopover
                                        size="small"
                                        maxWidth={400}
                                        transformHorizontalPosition={"left"}
                                        transformVerticalPosition={"center"}
                                        anchorHorizontalPosition={"right"}
                                        anchorVerticalPosition={"top"}
                                        hintContent={
                                            "If turned on, we are allowing callers to interrupt the assistant while the assistant is speaking. Once interrupted the assistant will stop speaking and listen to what the caller is saying."
                                        }
                                    />
                                </Typography>
                            </Grid>
                            <Grid item >
                                <Switch
                                    checked={additionalConfigObj.interruption}
                                    onChange={(e) =>
                                        handleChangeInterruptionSettings({
                                            target: { name: 'interruption', value: e.target.checked }
                                        })
                                    }
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent="center" marginBottom={1}>
                            <VoiceMail assistant={assistant} handleChange={handleChange} />
                        </Grid>
                        <Grid container xs={12} justifyContent="center" marginTop={2}>
                            <Accordion
                                defaultExpanded
                                expanded={advancedSettings.rengage_caller}
                                style={{
                                    width: "100%",
                                    paddingInline: 2,
                                    borderRadius: 24,
                                    boxShadow: "0px 0px 1px 0px",
                                }}
                            >
                                <AccordionSummary>
                                    <Grid
                                        xs={12}
                                        item
                                        container
                                        sx={{ height: "60px" }}
                                        alignItems="center"
                                        border={0}
                                        borderRadius={1}
                                        borderColor={theme.palette.divider}
                                        paddingInline={2}
                                    >
                                        <Grid item xs={6}>
                                            <Typography
                                                display="flex"
                                                variant="subtitle1"
                                                alignItems="center"
                                                fontWeight="bold"
                                            >
                                                Re-engage User
                                                <CustomHintPopover
                                                    size="small"
                                                    maxWidth={400}
                                                    transformHorizontalPosition={"left"}
                                                    transformVerticalPosition={"center"}
                                                    anchorHorizontalPosition={"right"}
                                                    anchorVerticalPosition={"top"}
                                                    hintContent={
                                                        <p>
                                                            Enable this setting to allow the assistant to
                                                            re-engage with the user if the user has not
                                                            responded after{" "}
                                                            <strong>No Response Time Limit</strong>. has
                                                            passed. Activate this to configure re-engagement
                                                            options.
                                                        </p>
                                                    }
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} container justifyContent="flex-end">
                                            <Switch
                                                checked={advancedSettings.rengage_caller}
                                                onChange={(e, newValue) =>
                                                    handleChangeAdvanceSettings(
                                                        "rengage_caller",
                                                        newValue
                                                    )
                                                }
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container item xs={12} paddingInline={2}>
                                        <Grid container item xs={12}>
                                            <Grid item xs={5}>
                                                <Typography
                                                    display="flex"
                                                    alignItems="center"
                                                    variant="subtitle1"
                                                >
                                                    Re-engage Count :
                                                    <CustomHintPopover
                                                        size="small"
                                                        maxWidth={400}
                                                        transformHorizontalPosition={"left"}
                                                        transformVerticalPosition={"center"}
                                                        anchorHorizontalPosition={"right"}
                                                        anchorVerticalPosition={"top"}
                                                        hintContent={
                                                            <>
                                                                Set the number of times the assistant will try
                                                                to re-engage with the user after waiting for the{" "}
                                                                <strong>No Response Time Limit</strong> to pass
                                                                each time. If this count is exceeded and the
                                                                user still does not respond, the call will end."
                                                            </>
                                                        }
                                                    />
                                                </Typography>
                                                <Typography variant="subtitle2" fontWeight="bold">
                                                    ( {advancedSettings.rengage_count} )
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Slider
                                                    step={1}
                                                    marks={marks}
                                                    valueLabelDisplay="auto"
                                                    disabled={!advancedSettings.rengage_caller}
                                                    value={advancedSettings.rengage_count}
                                                    onChange={(e, newValue) =>
                                                        handleChangeAdvanceSettings(
                                                            "rengage_count",
                                                            newValue
                                                        )
                                                    }
                                                    aria-labelledby="loudness-slider"
                                                    min={2}
                                                    max={5}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Grid item xs={5}>
                                                <Typography display="flex" variant="subtitle1">
                                                    Re-engage Message :
                                                    <CustomHintPopover
                                                        size="small"
                                                        maxWidth={400}
                                                        transformHorizontalPosition={"left"}
                                                        transformVerticalPosition={"center"}
                                                        anchorHorizontalPosition={"right"}
                                                        anchorVerticalPosition={"top"}
                                                        hintContent={
                                                            <>
                                                                This is the message the assistant will use to
                                                                re-engage with user if user haven't responded
                                                                after <strong>No Response Time Limit</strong>.
                                                            </>
                                                        }
                                                    />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <TextField
                                                    value={advancedSettings.rengage_message}
                                                    placeholder={`eg : Sorry, I didn't get any response from you, are you still there?`}
                                                    onChange={(e) =>
                                                        handleChangeAdvanceSettings(
                                                            "rengage_message",
                                                            e.target.value
                                                        )
                                                    }
                                                    fullWidth
                                                    multiline
                                                    rows={2}
                                                    variant="outlined"
                                                    sx={{ marginTop: 0.5 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        )
    );
};

export default CustomizationComponent;
