import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    Grid,
    TextField,
    Modal,
    Box,
    Typography,
    styled,
    Alert,
    IconButton
} from '@mui/material';
import { getLinkedInData } from '../../../../../data/store/assistantsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { handleCleverTapBtnClick, log } from "../../../../../data/configs/utils"
import CloseIcon from '@mui/icons-material/Close';
import ChatBot from './ChatBot';
import { THEME_MODE } from "../../../../../data/configs/constants"
import ScriptWithAIComponent from './AIScriptGeneration';


const Heading = styled(Typography)({
    fontWeight: 'bold',
    marginBottom: '8px',
});

const GenerativeAI = ({ isModalOpen, closeModal, assistant, handleChange, setsetAIScriptClosed, setIsOpenGenerateAssistantScript }) => {
    const [activeStep, setActiveStep] = useState(0);
    const workspaceId = useSelector((state) => state.authDetails?.user?.workspace);
    const [businessName, setBusinessName] = useState('');
    const [businessDescription, setBusinessDescription] = useState('');
    const [isEnableSaveToAssistant, setisEnableSaveToAssistant] = useState(false);
    const [streamedText, setStreamedText] = useState("");
    const [businessUrl, setBusinessUrl] = useState('');
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const [backendChat, setBackendChat] = useState([]);
    const bussinessDiscRef = useRef(null);
    const themeMode = useSelector((state) => state.pageDetails.themeMode)
    const [activeStepOne, setActiveStepOne] = useState(0);
    useEffect(() => {
        setOpen(isModalOpen);
        setStreamedText("");
        setBusinessUrl('');
        // setBusinessDescription('');
        // setBusinessName('');
    }, [isModalOpen]);

    const handleScriptChange = (value) => {
        setStreamedText(value);
        handleChange({ script: value });
    }

    const handleClose = () => {
        setOpen(false);
        closeModal();
        setActiveStep(0);
        setActiveStepOne(0);
        setBusinessName('');
        setBusinessDescription('');
    };
    const isValidUrl = (url) => {
        const pattern = /^(https?:\/\/)?(www\.)?[^ "]+(\.[a-zA-Z]{2,})(\/)?$/;
        return pattern.test(url);
    };

    const fetchLinkedinData = (url) => {

        if (isValidUrl(url)) {
            setError(false);
            dispatch(
                getLinkedInData({ domain: url })
            )
                .unwrap()
                .then((res) => {
                    setBusinessName(res.data.company_name);
                    setBusinessDescription(res.data.description);
                    setActiveStepOne(2);
                })
                .catch((err) => {
                    log("ERR-getAllActions", err)
                })
        } else {
            setError(true);
        }
    };


    const handleNext = () => {
        if (activeStep === 0) {
            setBackendChat([]);
            if (activeStepOne < 2) {
                setActiveStepOne((prevActiveStepOne) => prevActiveStepOne + 1);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setActiveStepOne(0);
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            if (activeStep === 2) {
                setStreamedText('');
            }
            setActiveStepOne(0);
        }
    };

    const handleSaveToAssistant = () => {
        handleChange({ script: streamedText })
        handleClose();
        setActiveStep(0);
        setBackendChat([]);
        setsetAIScriptClosed(true);
    };


    const handleKeyDownOnUrl = (e) => {
        if (businessUrl !== '' && e.keyCode === 13 && !e.shiftKey) {
            fetchLinkedinData(businessUrl)
        }

    }
    const handleKeyDownOnBussinessName = (e) => {
        if (businessName !== '' && e.keyCode === 13 && !e.shiftKey && bussinessDiscRef.current) {
            e.target.blur();
            bussinessDiscRef.current.focus();
        }
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }} >
                <Grid item xs={12} md={8} >
                    <Box sx={{
                        backgroundColor: themeMode === THEME_MODE.DARK ? "#444" : 'background.paper',
                        p: 4,
                        borderRadius: 1,
                        position: 'relative',
                        height: '100%', // Set the height
                        overflow: 'auto', // Enable scrolling if content exceeds height
                    }}>
                        <IconButton
                            size="small"
                            sx={{
                                zIndex: 1,
                                position: "absolute",
                                right: 12,
                                top: 12,
                                backgroundColor: "background.paper",
                                "&:hover": {
                                    backgroundColor: "background.default",
                                },
                            }}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                        {activeStep === 0 && (
                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                                {activeStepOne === 0 && (
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography variant="h5" align="center" gutterBottom>
                                                Choose One To Generate Script
                                            </Typography>
                                        </Grid>

                                        <Grid container item xs={12} align="center">
                                            <Grid item xs={12} mt={3}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        setActiveStepOne(1);
                                                        handleCleverTapBtnClick("Using Website Url");
                                                    }}
                                                    sx={{ minWidth: "300px", padding: 1.5 }}
                                                >
                                                    Using Website Url
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} mt={3}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        setActiveStepOne(2);
                                                        handleCleverTapBtnClick("Enter Manually");
                                                    }}
                                                    sx={{ minWidth: "300px", padding: 1.5 }}
                                                >
                                                    Enter Manually
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} align="center" mb={2} mt={3}>
                                            <Button variant="outlined" sx={{ minWidth: "180px" }} onClick={() => { handleClose(); setIsOpenGenerateAssistantScript(true); }}>
                                                Back
                                            </Button>

                                        </Grid>
                                    </Grid>
                                )}


                                {activeStepOne === 1 && (
                                    <Grid container spacing={2} justifyContent="center" alignItems="center" minHeight="350px">
                                        <Grid item xs={12}>
                                            <Typography variant="h5" align="center" gutterBottom>
                                                Fetch Data from URL
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <Heading variant="subtitle1">Enter Website URL:</Heading>
                                            <Grid container xs={8} justifyContent="space-between">
                                                <Grid item xs={9}>
                                                    <TextField
                                                        label="Business URL (Optional)"
                                                        value={businessUrl}
                                                        onChange={(e) => setBusinessUrl(e.target.value)}
                                                        fullWidth
                                                        onKeyDown={handleKeyDownOnUrl}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} align="end">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => fetchLinkedinData(businessUrl)}
                                                        disabled={!businessUrl}
                                                        fullWidth
                                                        sx={{ paddingBlock: 1.9, marginLeft: 1.5 }}
                                                    >
                                                        Fetch Data
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid item container xs={12} justifyContent="space-around" align="center">
                                            <Grid>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => setActiveStepOne(0)}
                                                    sx={{ minWidth: "150px", padding: 1 }}
                                                >
                                                    Back
                                                </Button>
                                            </Grid>
                                            <Grid>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNext}
                                                    sx={{ minWidth: "150px", padding: 1 }}
                                                    disabled={!(businessName && businessDescription)}
                                                >
                                                    Next
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStepOne === 2 && (
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography variant="h5" align="center" gutterBottom>
                                                Enter Business Details
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Heading variant="subtitle1">Enter Your Business Name:</Heading>
                                            <TextField
                                                label="Business Name"
                                                value={businessName}
                                                onChange={(e) => setBusinessName(e.target.value)}
                                                fullWidth
                                                onKeyDown={handleKeyDownOnBussinessName}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Heading variant="subtitle1">Enter Your Business Description :</Heading>
                                            <TextField
                                                inputRef={bussinessDiscRef}
                                                label="Business Description"
                                                value={businessDescription}
                                                onChange={(e) => setBusinessDescription(e.target.value)}
                                                fullWidth
                                                multiline
                                                rows={8}
                                                maxRows={5}
                                            />
                                        </Grid>
                                        <Grid item container xs={12} justifyContent="space-around" align="center">
                                            <Grid xs={5} item align="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => setActiveStepOne(0)}
                                                    sx={{ minWidth: "150px", padding: 1 }}
                                                >
                                                    Back
                                                </Button>
                                            </Grid>
                                            <Grid xs={5} item >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNext}
                                                    sx={{ minWidth: "150px", padding: 1 }}
                                                    disabled={!(businessName && businessDescription)}
                                                >
                                                    Next
                                                </Button>
                                            </Grid>


                                        </Grid>

                                    </Grid>
                                )}
                            </Grid>
                        )}
                        {activeStep === 1 && (

                            <ChatBot workspaceId={workspaceId} backendChat={backendChat} setBackendChat={setBackendChat} businessName={businessName} businessDescription={businessDescription} handleNext={handleNext} setActiveStep={setActiveStep} />

                        )}
                        {activeStep === 2 && (
                            // Render third component for Step 3
                            <Grid container sx={{ height: '80vh' }}>
                                <Grid item xs={12} align="center">
                                    <Typography variant="h4" gutterBottom>
                                        FINAL SCRIPT
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ScriptWithAIComponent backendChat={backendChat} businessName={businessName} businessDescription={businessDescription} setisEnableSaveToAssistant={setisEnableSaveToAssistant} streamedText={streamedText} setStreamedText={setStreamedText} handleScriptChange={handleScriptChange} assistant={assistant} handleChange={handleChange} setsetAIScriptClosed={setsetAIScriptClosed} />
                                </Grid>
                                <Grid item container xs={12} align="center" marginTop={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setActiveStep(0);
                                                setBusinessName('');
                                                setBusinessDescription('');
                                                handleCleverTapBtnClick("Generate Again");
                                            }}
                                        >
                                            Generate Again
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSaveToAssistant}
                                            disabled={!isEnableSaveToAssistant}
                                        >
                                            Move to Assistant
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </Grid>
            </Grid >
        </Modal >
    );
};

export default GenerativeAI;
