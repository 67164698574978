import { configureStore, combineReducers } from "@reduxjs/toolkit"
import logger from "redux-logger"

import { clearAllDataFromLocalStorage } from "../configs/utils"
import { resetReduxState } from "./authSlice"

import authMiddleware from "./authSlice/middleware"

import pageSlice from "./pageSlice"
import authSlice from "./authSlice"
import settingsSlice from "./settingsSlice"
import campaignsSlice from "./campaignsSlice"
import contactListSlice from "./contactListSlice"
import phoneNumbersSlice from "./phoneNumbersSlice"
import voiceAgentSlice from "./voiceAgentSlice"
import assistantsSlice from "./assistantsSlice"
import knowledgeBasesSlice from "./knowledgeBasesSlice"
import callHistorySlice from "./callHistorySlice"
import integrationSlice from "./integrationSlice"
import developersSlice from "./developersSlice"

const appReducer = combineReducers({
  pageDetails: pageSlice,
  authDetails: authSlice,
  settingsDetails: settingsSlice,
  contactListDetails: contactListSlice,
  integrationDetails: integrationSlice,
  developersDetails: developersSlice,
  phoneNumbersDetails: phoneNumbersSlice,
  campaignsDetails: campaignsSlice,
  voiceAgentDetails: voiceAgentSlice,
  assistantsDetails: assistantsSlice,
  knowledgeBasesDetails: knowledgeBasesSlice,
  callHistoryDetails: callHistorySlice,
})

const rootReducer = (state, action) => {
  if (resetReduxState.match(action)) {
    clearAllDataFromLocalStorage()
    return appReducer(action.payload, action)
  }

  return appReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware().concat(authMiddleware)
    if (process.env.NODE_ENV !== "production")
      middleware = middleware.concat(logger)
    return middleware
  },
  devTools: process.env.NODE_ENV !== "production",
})

export default store
