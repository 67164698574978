import React, { useState } from 'react';
import { Grid } from '@mui/material';
import ScriptExpandingCard from './AssistantTrainScriptCard';
import CallOpeningExpandingCard from './AssistantTrainCallOpeningCard';

const AssistantTraining = ({ comCorrection, setComCorrection, mentionList, handleChange, assistant, isSaveClicked, setIsSavedClicked, fromOnboarding }) => {
    const [goalExpanded, setGoalExpanded] = useState(false);
    const [scriptExpanded, setScriptExpanded] = useState(true);
    const handleCallOpeningExpandClick = () => {
        setGoalExpanded(!goalExpanded);
    };
    const handleScriptExpandClick = () => {
        setScriptExpanded(!scriptExpanded);
    };
    return (
        <Grid container spacing={2} >
            <Grid item xs={12}>
                <CallOpeningExpandingCard
                    comCorrection={comCorrection}
                    setComCorrection={setComCorrection}
                    isExpanded={goalExpanded}
                    handleExpandClick={handleCallOpeningExpandClick}
                    cardTitle="CALL OPENING MESSAGE"
                    handleChange={handleChange}
                    assistant={assistant}
                    mentionList={mentionList}
                />
            </Grid>
            <Grid item xs={12}>
                <ScriptExpandingCard
                    mentionList={mentionList}
                    isExpanded={true}
                    handleExpandClick={handleScriptExpandClick}
                    cardTitle="SCRIPT"
                    handleChange={handleChange}
                    script={assistant?.script}
                    assistant={assistant}
                    isSaveClicked={isSaveClicked}
                    setIsSavedClicked={setIsSavedClicked}
                    fromOnboarding={fromOnboarding}
                />
            </Grid>
        </Grid>
    );
};

export default AssistantTraining;
