import React, { useEffect, useRef, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import CustomHintPopover from '../../../CustomHintPopover';
import { COUNTRY_NAME_BASED_ON_CODE } from '../../../../data/configs/constants';
const VoiceAssistant = ({
    updatedData,
    handleInputChange,
    LANG_CODES,
    gender,
    getAudioUrl,
    selectedLanguage,
    selectedGender,
    filteredVoices,
    handleSelectedAccent,
}) => {

    const [isPlaying, setIsPlaying] = useState(null);
    const audioRefs = useRef({});

    const playPauseHandler = (item) => {
        const audioRef = audioRefs.current[item.label];
        if (isPlaying === item.label) {
            audioRef.pause();
            setIsPlaying(null);
        } else {
            audioRef.src = getAudioUrl(item.lang, item.label);
            audioRef.play();
            setIsPlaying(item.label);
        }
    };

    const handleAudioEnded = (item) => {
        setIsPlaying(null);
    };

    return (
        <Grid container display='flex' justifyContent='space-between'>
            <Grid item xs={4} display='flex' alignItems='center' pl={1}>
                <Typography display='flex' alignItems='flex-end' variant='subtitle1' >Choose Accent :
                    <CustomHintPopover
                        size="small"
                        maxWidth={400}
                        transformHorizontalPosition={'left'}
                        transformVerticalPosition={'center'}
                        anchorHorizontalPosition={'right'}
                        anchorVerticalPosition={'top'}
                        hintContent={"Choose accent based on your requirements."}
                    />

                </Typography>
            </Grid>
            <Grid item xs={8}>
                <FormControl sx={{ mt: 0.1 }} fullWidth>
                    <InputLabel id="choose-accent-label">Choose Accent</InputLabel>
                    <Select
                        labelId="choose-accent-label"
                        label="Choose Accent"
                        value={handleSelectedAccent()}
                        onChange={(e) => {
                            e.stopPropagation();
                            handleInputChange(e.target.value);
                        }}
                        MenuProps={{
                            sx: { maxHeight: 400 },
                        }}
                        displayEmpty
                        required
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: !handleSelectedAccent() && '#FF0000',
                            },
                        }}
                    >
                        {filteredVoices?.length > 0 && filteredVoices?.map((item) => (
                            <MenuItem key={item.label} value={item.label}>
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={6} sx={{ display: "grid", alignItems: "center" }} >
                                        <Typography variant='body1'>{item.label}</Typography>
                                    </Grid>
                                    <Grid item xs={2} sx={{ display: "grid", alignItems: "center" }} align='center'>
                                        <Typography>
                                            {COUNTRY_NAME_BASED_ON_CODE[item.country] ?? item.country}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        align="center"

                                    >
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                playPauseHandler(item);
                                            }}
                                            style={{ cursor: 'pointer', margin: "-10px" }}
                                        >
                                            {isPlaying === item.label ? <PauseIcon fontSize='small' /> : <PlayArrowIcon fontSize='small' />}
                                        </IconButton>
                                        <audio ref={(ref) => {
                                            if (ref) {
                                                audioRefs.current[item.label] = ref;
                                                ref.addEventListener('ended', () => handleAudioEnded(item));
                                            }
                                        }} />
                                    </Grid>
                                </Grid>
                            </MenuItem>

                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {!handleSelectedAccent() && (
                <Grid item xs={12}>
                    <Typography align='end' paddingInline={2} variant="subtitle2" color='error'>
                        Choose Voice Accents !
                    </Typography>
                </Grid>
            )}


        </Grid>

    );
};

VoiceAssistant.propTypes = {
    updatedData: PropTypes.object.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    LANG_CODES: PropTypes.object.isRequired,
    gender: PropTypes.string.isRequired,
};

export default VoiceAssistant;
