import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Dialog from "@mui/material/Dialog"

import { updatePageState } from "../../data/store/pageSlice"

import ErrorBoundary from "../ErrorBoundary"
import BlackFridayContent from "../BlackFridayContent"
import { IconButton } from "@mui/material"
import Close from "@mui/icons-material/Close"

const GlobalInformation = () => {
  const dispatch = useDispatch()
  const currentPage = useSelector((state) => state.pageDetails.currentPage)
  const pageLoading = useSelector((state) => state.pageDetails.pageLoading)
  const showModal = useSelector((state) => state.pageDetails.showModal)
  const pageLoaderText = useSelector(
    (state) => state.pageDetails.pageLoaderText
  )

  useEffect(() => {
    document.title = currentPage ? `${currentPage} | VoiceGenie` : "VoiceGenie"
  }, [currentPage])

  const handleCloseModal = (event, reason) => {
    if (reason !== "backdropClick")
      dispatch(updatePageState({ showModal: false }))
  }

  if (showModal)
    return (
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        PaperProps={{ sx: { width: 700, maxWidth: "100%" } }}
        fullWidth
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
            color: "common.white",
          }}
          onClick={handleCloseModal}
        >
          <Close />
        </IconButton>
        <ErrorBoundary>
          <BlackFridayContent />
        </ErrorBoundary>
      </Dialog>
    )
  else if (pageLoading)
    return (
      <ErrorBoundary>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }}
          open={pageLoading}
        >
          <Paper sx={{ p: 2, display: "flex", alignItems: "center" }}>
            <CircularProgress color="secondary" />
            <Typography
              sx={{ mx: 2, textTransform: "capitalize", maxWidth: 250 }}
            >
              {pageLoaderText}...
            </Typography>
          </Paper>
        </Backdrop>
      </ErrorBoundary>
    )
  else return false
}

export default GlobalInformation
