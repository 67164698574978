import { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { enqueueSnackbar } from "notistack"
import Box from "@mui/material/Box"
import * as _ from "lodash"
import LoadingButton from "@mui/lab/LoadingButton"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Link from "@mui/material/Link"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"

import { ROUTE_PATH, APP_PAGES, LOCAL_STORAGE } from "../../../data/configs/constants"
import { updatePageState } from "../../../data/store/pageSlice"
import { signupUser } from "../../../data/store/authSlice"
import { log, parseQueryString, setDataInLocalStorage } from "../../../data/configs/utils"

import LoginWithGoogle from "../../../components/LoginWithGoogle"

const AppSumoSignup = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [email, setEmail] = useState("")
    const [showDialog, setShowDialog] = useState(false)
    const loading = useSelector((state) => state.pageDetails.authLoading)
    const restParams = useLocation()
    const restParamsObj = parseQueryString(restParams?.search)

    useEffect(() => {
        setDataInLocalStorage(LOCAL_STORAGE.AUTH_SEARCH_PARAMS, window.location.search);
        setDataInLocalStorage(
            LOCAL_STORAGE.APP_SUMO_CODE,
            !_.isEmpty(restParamsObj) ? restParamsObj : ''
        );
        dispatch(
            updatePageState({
                currentPage: APP_PAGES.APPSUMO_SIGNUP,
                currentActiveModule: "",
            })
        )
    }, [dispatch, restParams])

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(signupUser({ email, ...restParamsObj }))
            .unwrap()
            .then((res) => {
                if (!showDialog) setShowDialog(true)
                enqueueSnackbar(
                    res.message
                        ? res.message
                        : "Link has been sent to your email. Please check your mail !",
                    { variant: "success" }
                )
            })
            .catch((err) => log("ERROR-signupUser: ", err))
    }

    return (
        <>
            <Box
                className="vg-animated vg-fadeIn"
                component="form"
                autoComplete="off"
                onSubmit={handleSubmit}
                sx={{ width: 1 }}
            >
                <Box mb={1} textAlign="center">
                    <Typography variant="h5" component="span" fontWeight="fontWeightBold">
                        Welcome to the
                    </Typography>
                    <Typography
                        variant="h5"
                        fontWeight="fontWeightBold"
                        component="span"
                        color="primary.main"
                    >
                        {" "}
                        Voice revolution
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Typography
                        align="center"
                        variant="subtitle2"
                        mb={3}
                        component="span"
                        maxWidth="300px"
                    >
                        <Typography component="span" color="primary">
                            Sign up {""}
                        </Typography>
                        and amplify your business with cutting edge voice tech
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <LoginWithGoogle sx={{ px: 1.5, py: 1, boxShadow: 7 }} />
                </Box>
                <Typography variant="subtitle2" align="center" mt={1}>
                    OR
                </Typography>
                <Typography
                    align="center"
                    mb={1}
                    color="primary.main"
                    fontWeight="fontWeightMedium"
                >
                    Create an account
                </Typography>

                <TextField
                    sx={{ mb: 3, boxShadow: 7, borderRadius: 3 }}
                    id="email"
                    type="email"
                    size="small"
                    placeholder="Please enter your email ID"
                    variant="outlined"
                    autoComplete="username"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailOutlinedIcon
                                    fontSize="small"
                                    sx={{ color: "text.disabled" }}
                                />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    required
                />
                <LoadingButton
                    sx={{
                        mt: 2,
                    }}
                    type="submit"
                    variant="contained"
                    loading={loading}
                    disableElevation
                    fullWidth
                >
                    Sign Up
                </LoadingButton>
            </Box>
            <Typography mt={1} align="center">
                Already a user?
                <Typography
                    ml={1}
                    component="span"
                    fontWeight="fontWeightBold"
                    sx={{
                        cursor: "pointer",
                        color: "primary.main",
                        textDecoration: "underline",
                    }}
                    onClick={() => navigate(ROUTE_PATH.LOGIN + window.location.search)}
                >
                    Sign in
                </Typography>
            </Typography>
            <Dialog
                aria-labelledby="vg-signup-password"
                open={showDialog}
                onClose={() => setShowDialog(false)}
                fullWidth
            >
                <IconButton
                    aria-label="close"
                    onClick={() => setShowDialog(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        px={3}
                    >
                        <Typography variant="h6" mb={3} color="primary">
                            Please verify your email
                        </Typography>
                        <Typography variant="subtitle1" textAlign="center">
                            You're almost there! We sent an email to{" "}
                            <Typography fontWeight="fontWeightBold">{email}</Typography>
                        </Typography>
                        <Typography
                            my={2}
                            variant="subtitle1"
                            textAlign="center"
                            lineHeight={1.4}
                            color="text.secondary"
                        >
                            Just click on the link in that email to complete your signup. if
                            you don't see it, you may need to check your spam folder.
                        </Typography>
                        <Typography mb={0.5} variant="caption">
                            Still can't find the email?
                        </Typography>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Resend Email
                        </Button>
                        {process.env.REACT_APP_SUPPORT_EMAIL && (
                            <Typography mt={2}>
                                Need help to mail {""}
                                <Link href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
                                    {process.env.REACT_APP_SUPPORT_EMAIL}
                                </Link>
                            </Typography>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AppSumoSignup
