import { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { GET_CALL_RECORDING_DETAILS } from '../store/campaignsSlice/api';

const useAudioStreamTwilio = (audioUrlPresent, restParamsObj, token) => {
    const [audio, setAudio] = useState(null);
    const [error, setError] = useState(null);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const toQueryString = (params) => {
        return new URLSearchParams(params).toString();
    };

    const fetchAudio = useCallback(async (payload) => {
        try {
            setError(null);
            const queryString = toQueryString(payload);
            const response = await fetch(`${baseUrl}${GET_CALL_RECORDING_DETAILS}?${queryString}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            });
            if (!response.ok) {
                setError(response?.status);
            }
            const arrayBuffer = await response.arrayBuffer();
            const blob = new Blob([arrayBuffer], { type: 'audio/mp3' });
            const url = URL.createObjectURL(blob);
            setAudio(url);
        } catch (error) {
            console.error('Error fetching audio:', error);
            setError(error);
        }
    }, [baseUrl, token]);

    useEffect(() => {
        if (!_.isEmpty(audioUrlPresent) && !_.isEmpty(restParamsObj) && restParamsObj?.campaignId !== 'demo') {
            fetchAudio({ ...restParamsObj, mediaType: 'mp3' });
        }
        // Clean up the URL object when the component unmounts
        return () => {
            if (audio) {
                URL.revokeObjectURL(audio);
            }
        };
    }, [audioUrlPresent]);

    return { audio, error };
};

export default useAudioStreamTwilio;
