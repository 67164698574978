import React, { useEffect, useMemo, useState } from "react";
import * as _ from "lodash";
import {
  Grid,
  Typography,
  Box,
  Switch,
  useTheme,
  IconButton,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Card,
  TextField,
  InputAdornment,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  postCreateAction,
  deleteAction,
  getAllActions,
  updateAssistant,
} from "../../../../data/store/assistantsSlice";
import { isValidUrl, log } from "../../../../data/configs/utils";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CustomHintPopover from "../../../CustomHintPopover";
import { enqueueSnackbar } from "notistack";

const ActionWebhook = ({
  saveChanges,
  setSaveChanges,
  handleSaveChangesUpdate,
  handleSaveChanges,
  allActionData,
  setAllActionData,
  handleChange,
  isChanged,
  assistant,
  inititalWebhookUrls,
  setInititalWebhookUrls,
  actionIds,
  setActionIds

}) => {
  const [webhookActionData, setWebhookActionData] = useState([]);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [webhookName, setWebhookName] = useState("");
  const [deletingParticularWebhook, setDeletingParticularWebhook] = useState(
    []
  );
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [openAddNumberDialog, setOpenAddNumberDialog] = useState(false);
  const userQuota = useSelector((state) => state.authDetails?.user?.userQuota);
  const [showInput, setShowInput] = useState(false);
  const [isDeleteFromAllDialogOpen, setIsDeleteFromAllDialogOpen] =
    useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [assistantTransferToAgentValue, setAssistantTransferToAgentValue] =
    useState(assistant?.webhook ?? false);
  const [selectedOpen, setSelectedOpen] = useState(false);
  const [agentQuota, setAgentQuota] = useState(
    userQuota?.find((quota) => quota.label === "Agents")?.remaining ?? 0
  );
  const [filteredWebhookActionData, setFilteredWebhookActionData] = useState(
    []
  );

  const urlsArray = useMemo(() => {
    const urls = inititalWebhookUrls.map((item) => item.url);
    return urls;
  }, [inititalWebhookUrls]);

  const handleExtractWebhooksAndActions = () => {
    const actions = assistant?.actions || [];

    actions.forEach(({ data, _id }) => {
      if (data?.url) {
        setInititalWebhookUrls((prevState) =>
          prevState.some((webhook) => webhook.url === data.url)
            ? prevState
            : [...prevState, { url: data.url, id: _id }]
        );


      }
      if (data) {
        setActionIds((prevActionIds) =>
          prevActionIds.includes(_id) ? prevActionIds : [...prevActionIds, _id]
        );
      }
    });
  };



  const handleAddWebhook = () => {
    if (!isValidUrl(webhookUrl)) {
      enqueueSnackbar(`Please enter a valid Webhook Url.`, {
        variant: "error",
      });
      return;
    }
    if (urlsArray?.includes(webhookUrl)) {
      enqueueSnackbar("Webhook Url is already present.", {
        variant: "warning",
      });
      return;
    }

    if (assistant?.webhook === false)
      handleChange({ webhook: true });

    createAction();

    setShowInput(false);
    setOpenAddNumberDialog(false);
  };

  const handleAddExistingWebhook = (url, id) => {
    if (assistant?.webhook === false) handleChange({ webhook: true });
    inititalWebhookUrls.push({ url, id });
    setInititalWebhookUrls([...inititalWebhookUrls]);
    actionIds.push(id)
    handleChange({ actions: actionIds });
    setOpen(false);
    handleFilterAllActionData();
    filterData(inititalWebhookUrls);
    handleSaveChangesUpdate('webhook', true);

  };

  const filterData = (updatedInitialUrls) => {
    const filteredData = webhookActionData.filter(
      (item) => !updatedInitialUrls.includes(item?.data?.url)
    );
    setFilteredWebhookActionData(filteredData);
  };

  const handleAddExistingWebhookUrlDialogBox = (url, id) => {
    setSelectedUrl({
      url: url,
      id: id,
    });
    setSelectedOpen(true);
  };

  const handleFilterAllActionData = () => {
    const filteredData = allActionData?.filter(
      (item) => item.type === "webhook"
    );
    setWebhookActionData(filteredData);
    const urls = inititalWebhookUrls.map((item) => item.url);
    setFilteredWebhookActionData(
      filteredData.filter((item) => !urls.includes(item?.data?.url))
    );
  };

  const handleDeleteWebhookDialog = (obj) => {
    setDeletingParticularWebhook({
      url: obj.url ?? "url missing",
      id: obj.id ?? "id missing",
    });
    setOpen(true);
  };

  const handleDeleteWebhook = (id) => {
    const updatedUrls = inititalWebhookUrls.filter((obj) => obj.id !== id);
    setInititalWebhookUrls(updatedUrls);
    const UpdateActionIds = actionIds.filter((actionId) => actionId !== id);
    setActionIds(UpdateActionIds);
    handleChange({ actions: UpdateActionIds });
    setDeletingParticularWebhook([]);
    if (updatedUrls?.length === 0) {
      handleChange({ webhook: false });
      setAssistantTransferToAgentValue(false);
      setShowInput(false);
    }
    handleSaveChangesUpdate('webhook', true);

    return updatedUrls;
  };

  const getAllActionData = () => {
    dispatch(getAllActions())
      .unwrap()
      .then((res) => {
        setAllActionData(res?.data?.actions);
        if (res.status === "success") {
          handleSaveChangesUpdate('webhook', true);

        }
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      });
  };

  useEffect(() => {
    if (saveChanges?.webhook) {
      handleSaveChanges();
      handleSaveChangesUpdate('webhook', false);
    }
  }, [saveChanges]);

  const createAction = () => {
    dispatch(
      postCreateAction({
        type: "webhook",
        data: {
          url: webhookUrl,
        },
        triggerPrompts: [],
        name: "Webhook",
        description: `"webhook name is ${webhookName}"`,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.data && res.data.action && res.data.action._id) {
          actionIds.push(res.data.action._id)
          handleChange({ actions: actionIds });
          setInititalWebhookUrls((prevState) => [
            ...prevState,
            {
              url: res.data.action.data.url,
              id: res.data.action._id,
            },
          ]);
        }
        if (res?.status === "success") {
        }
        getAllActionData();
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      });
    handleAddWebhookDialogClose();
    setWebhookUrl("");
    setWebhookName("");
  };

  const handleDeleteAction = (id) => {
    dispatch(deleteAction({ id }))
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          setAgentQuota((prev) => prev + 1);
        }
        const updatedNewUrls = handleDeleteWebhook(
          deletingParticularWebhook.id
        );
        if (updatedNewUrls?.length === 0) {
          handleChange({ webhook: false });
          setAssistantTransferToAgentValue(false);
          setShowInput(false);
        }
        const updatedUrls = inititalWebhookUrls.filter((obj) => obj.id !== id);
        setInititalWebhookUrls(updatedUrls);
        getAllActionData();
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      });
  };

  const handleWebhookAccordianToggle = (value) => {
    if (
      inititalWebhookUrls?.length > 0 &&
      value === true &&
      assistant?.webhook !== true
    )
      handleChange({ webhook: value });
    else if (value === false && assistant?.webhook !== false) {
      handleChange({ webhook: value });
    }
    setAssistantTransferToAgentValue(value);
  };


  useEffect(() => {
    handleExtractWebhooksAndActions();
    handleFilterAllActionData();
  }, [allActionData]);

  useEffect(() => {
    handleFilterAllActionData();
  }, [inititalWebhookUrls, allActionData]);

  const handleToggleDialog = () => {
    setOpen(!open);
  };

  const handleAddWebhookDialogClose = () => {
    setOpenAddNumberDialog(false);
    setShowInput(false);
    setWebhookUrl("");
    setWebhookName("");
  };

  return (
    <Grid container xs={12} justifyContent="center" marginTop={1}>
      <Accordion
        defaultExpanded
        expanded={assistantTransferToAgentValue}
        style={{
          width: "100%",
          borderRadius: 24,
          boxShadow: "0px 0px 1px 0px",
        }}
      >
        <AccordionSummary>
          <Grid
            xs={12}
            item
            container
            sx={{ height: "60px" }}
            alignItems="center"
            border={0}
            borderRadius={1}
            borderColor={theme.palette.divider}
            paddingInline={2}
          >
            <Grid item xs={6}>
              <Typography display="flex" variant="h6" alignItems="center">
                Webhook
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"center"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    <>
                      <p>
                        Enter a POST HTTP endpoint to receive post call data on.
                        This can be any POST webhook url from services like
                        Zapier, goHighLevel, your personal service endpoint etc.
                      </p>
                      <br />
                      <p>
                        <strong>Use Cases :</strong> Can be used for meeting
                        bookings, Mail/Message(s) sending, etc.
                      </p>
                    </>
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <Switch
                defaultChecked={assistant?.webhook}
                checked={assistantTransferToAgentValue}
                inputProps={{ "aria-label": "controlled" }}
                onChange={(event) => {
                  handleWebhookAccordianToggle(event.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container xs={12}>
            <Grid xs={12} item container paddingInline={4}>
              {inititalWebhookUrls?.length > 0 ? (
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  {inititalWebhookUrls?.flatMap((obj) => (
                    <Grid item xs={12} key={obj}>
                      <Card
                        sx={{
                          padding: 1,
                          borderRadius: "10px",
                          bgcolor: "#d8e7ff",
                        }}
                      >
                        <Grid container xs={12}>
                          <Grid
                            item
                            xs={10}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 20, paddingInline: 1 }}
                              color="text.primary"
                            >
                              {obj?.url}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              onClick={() => handleDeleteWebhookDialog(obj)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : null}

              <Grid item xs={12} align="start" marginTop={-2}>
                <Tooltip
                  title={
                    inititalWebhookUrls?.length >= 5
                      ? "You can add maximum 5 webhooks. Delete anyone to add another."
                      : ""
                  }
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      onClick={() => setOpenAddNumberDialog(true)}
                      variant="contained"
                      startIcon={<AddIcon />}
                      disabled={inititalWebhookUrls?.length >= 5}
                      sx={{
                        width: "200px",
                        marginBlock: 4,
                        borderRadius: "10px !important",
                      }}
                    >
                      Add Webhook
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Dialog
        PaperProps={{
          style: {
            maxWidth: "1400px",
          },
        }}
        open={openAddNumberDialog}
        onClose={handleAddWebhookDialogClose}
      >
        <DialogTitle>
          <Typography align="center" variant="h6" fontWeight="bold">
            Add Webhook
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleAddWebhookDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            mt={2}
            mb={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "650px !important",
            }}
          >
            <Grid
              container
              spacing={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                  color="text.secondary"
                >
                  Webhook Url :
                  <CustomHintPopover
                    size="small"
                    maxWidth={400}
                    // hintTitle={'Webhook Formate'}
                    transformHorizontalPosition={"left"}
                    transformVerticalPosition={"center"}
                    anchorHorizontalPosition={"right"}
                    anchorVerticalPosition={"top"}
                    hintContent="Webhooks can be used for zapier automation."
                  />
                </Typography>
              </Grid>
              <Grid
                item
                xs={9}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <TextField
                  id="url"
                  type="url"
                  size="small"
                  placeholder="Please enter webhook url"
                  variant="outlined"
                  value={webhookUrl}
                  onChange={(e) => setWebhookUrl(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Box>

          <Grid
            container
            item
            xs={12}
            paddingInline={2}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={8}>
              <Typography variant="caption">
                <p>
                  <strong>Webhook Url Formate:</strong>
                </p>
                <p>✅ : Start with http:// or https://</p>
                <p>
                  ✅ : Include a valid domain name (e.g., example.com) and avoid
                  spaces.
                </p>
                <p>✅ : End with a valid top-level domain (e.g., .com, .org)</p>
              </Typography>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="flex-end">
              <Typography variant="caption">
                <p>
                  <strong>Examples :</strong>
                </p>
                <p>https://example.com</p>
                <p>https://example.com/path/to/resource</p>
                <p>https://www.example.com</p>
                <p>https://example.com/search?q=openai</p>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} align="center" mt={2} mb={6}>
            <Button
              style={{ minWidth: "200px", paddingBlock: 4, paddingInline: 15 }}
              variant="contained"
              onClick={handleAddWebhook}
            >
              <Typography align="center" variant="body1" textTransform="none">
                {" "}
                Add Webhook
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: 2, justifyContent: "center" }}>
            {filteredWebhookActionData?.length !== 0 && (
              <FormControl style={{ width: "100%" }}>
                <Select
                  value=""
                  displayEmpty
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      paddingBlock: 1,
                    },
                    justifyContent: "center",
                  }}
                  fullWidth
                >
                  <MenuItem
                    value=""
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <em>Select Existing Webhooks</em>
                  </MenuItem>
                  {filteredWebhookActionData?.map((item) => (
                    <MenuItem
                      key={item._id}
                      value={item._id}
                      onClick={() =>
                        handleAddExistingWebhookUrlDialogBox(
                          item.data.url,
                          item._id
                        )
                      }
                    >
                      {item?.data?.url}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Typography align="center" variant="h6" fontWeight="bold">
            Delete Webhook
          </Typography>

          <IconButton
            aria-label="close"
            onClick={handleToggleDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" mb={1}>
            Are you sure you want to delete this webhook{" "}
            {deletingParticularWebhook.url}?
          </Typography>
          <Box mt={2}>
            <Grid container spacing={2} mb={2} mt={1}>
              <Grid item xs={6}>
                <Tooltip
                  title={`This action will delete the Webhook ${deletingParticularWebhook.url} from the current assistant`}
                >
                  <Button
                    style={{ minWidth: "200px", paddingBlock: 12 }}
                    variant="contained"
                    onClick={() => {
                      handleDeleteWebhook(deletingParticularWebhook.id);
                      handleToggleDialog();
                    }}
                    fullWidth
                    endIcon={<DeleteIcon />}
                  >
                    <Typography variant="body2" textTransform="none">
                      From Current Assistant
                    </Typography>
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip
                  title={`This action will delete the Webhook Url ${deletingParticularWebhook.url} from the all assistant`}
                >
                  <Button
                    style={{ minWidth: "200px", paddingBlock: 12 }}
                    variant="contained"
                    onClick={() => {
                      handleToggleDialog();
                      setIsDeleteFromAllDialogOpen(true);
                    }}
                    color="error"
                    fullWidth
                    endIcon={<DeleteIcon />}
                  >
                    <Typography variant="body2" textTransform="none">
                      From All Assistants
                    </Typography>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteFromAllDialogOpen}
        onClose={() => setIsDeleteFromAllDialogOpen(false)}
      >
        <DialogTitle>
          <Typography align="center" variant="h6" fontWeight="bold">
            {" "}
            Delete Webhook From All Assistants
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setIsDeleteFromAllDialogOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} align="center">
            <Typography variant="body1">
              Are you sure you want to delete this Webhook{" "}
              {deletingParticularWebhook.url} from all assistants?
            </Typography>
          </Grid>
          <Box mt={2}>
            <Grid item xs={12} align="center">
              <Button
                style={{
                  minWidth: "200px",
                  paddingBlock: 12,
                  paddingInline: 15,
                }}
                variant="contained"
                onClick={() => {
                  handleDeleteAction(deletingParticularWebhook?.id);
                  setIsDeleteFromAllDialogOpen(false);
                }}
                color="error"
                endIcon={<DeleteIcon />}
              >
                <Typography align="center" variant="body1" textTransform="none">
                  Confirm Delete
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} align="center" mt={2}>
              <Typography variant="caption">
                This action will remove this {deletingParticularWebhook.url}{" "}
                Webhook from all the assistant you have.{" "}
              </Typography>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Dialog open={selectedOpen} onClose={() => setSelectedOpen(false)}>
            <DialogTitle align="center">Confirm Webhook Addition</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ color: "#444" }}>
                Are you sure you want to add this webhook{" "}
                <strong>{selectedUrl?.url}</strong> to the{" "}
                <strong>{assistant?.name ?? "existing"}</strong> assistant?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 2 }}>
              <Button onClick={() => setSelectedOpen(false)} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleAddExistingWebhook(selectedUrl.url, selectedUrl.id);
                  setSelectedOpen(false);
                  handleAddWebhookDialogClose();
                }}
                color="primary"
              >
                Add Webhook
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActionWebhook;
