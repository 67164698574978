import React, { useState } from "react";
import * as _ from "lodash";
import { Grid, Typography, Switch, useTheme, Card, Chip } from "@mui/material";
import CustomHintPopover from "../CustomHintPopover";

const VoiceMail = ({ assistant, handleChange }) => {
  const theme = useTheme();

  const [assistantMachineDetection, setAssistantMachineDetection] = useState(
    assistant?.additionalConfig?.machine_detection ?? false
  );

  const handleVoiceMailToggle = (value) => {
    handleChange({
      additionalConfig: {
        ...(assistant?.additionalConfig && assistant?.additionalConfig),
        machine_detection: value,
      },
    });
    setAssistantMachineDetection(!assistantMachineDetection);
  };

  return (
    <Grid container xs={12} mt={2}>
      <Card
        sx={{
          width: "100%",
          paddingInline: 2,
          boxShadow: "0px 0px 1px 0px",
        }}
      >
        <Grid
          xs={12}
          item
          container
          sx={{ height: "60px" }}
          alignItems="center"
          border={0}
          borderRadius={1}
          borderColor={theme.palette.divider}
          paddingInline={2}
        >
          <Grid item xs={6}>
            <Typography
              display="flex"
              variant="subtitle1"
              alignItems="center"
              fontWeight="bold"
            >
              Voicemail
              <CustomHintPopover
                size="small"
                maxWidth={400}
                transformHorizontalPosition={"left"}
                transformVerticalPosition={"center"}
                anchorHorizontalPosition={"right"}
                anchorVerticalPosition={"top"}
                hintContent={
                  <>
                    <p>
                      <strong>Voicemail Detection: </strong>Toggle to enable or
                      disable answering machine detection for outbound calls.
                    </p>
                    <br />
                    <p>
                      <strong>Enabled: </strong>Detects answering machines or
                      voicemails and disconnects the call without deducting
                      minutes from your quota.
                    </p>
                    <br />
                    <p>
                      <strong>Disabled: </strong>All calls proceed without
                      detection.
                    </p>
                    <br />
                    <p>
                      <strong>
                        Note:{" "}
                        <p>
                          This feature is currently disabled for{" "}
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Plivo Dialer
                          </span>
                          .
                        </p>
                      </strong>
                    </p>
                  </>
                }
              />
            </Typography>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <Switch
              defaultChecked={assistant?.machine_detection}
              checked={assistantMachineDetection}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(event) => handleVoiceMailToggle(event.target.checked)}
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default VoiceMail;
