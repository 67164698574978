import React from "react"
import PropTypes from "prop-types"
import Typography from "@mui/material/Typography"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import StepConnector from "@mui/material/StepConnector"
import Box from "@mui/material/Box"
import { Grid, darken, lighten } from "@mui/material"

import { THEME_MODE, TOGGLE_BUTTON } from "../../data/configs/constants"

const StepperUI = ({ data, activeService }) => {
  return (
    <Grid container sx={{ display: "flex", alignItems: "space-between", minHeight: { xs: "200px", md: "420px" } }}>
      <Grid item>
        <Typography variant="h6" color="primary" mb={1}>
          {data?.title}
        </Typography>

        <Box
          bgcolor={(theme) =>
            theme.palette.mode === THEME_MODE.DARK
              ? darken(theme.palette.info.dark, 0.8)
              : lighten(theme.palette.info.light, 0.8)
          }
          borderRadius={1}
          fontSize="body2.fontSize"
          p={1.5}
          mb={2}
        >{
            activeService === TOGGLE_BUTTON.toggleButtonDefault ?
              (
                <>
                  <strong>Note:</strong> Twilio offers{" "}
                  <strong>$15 in complimentary credit</strong> for{" "}
                  <strong>new Twilio Users on their Twilio account</strong> which can be
                  used to purchase a Twilio number and make <strong>outbound calls</strong>{" "}
                  using that number.
                </>

              )
              :
              (
                <>
                  <strong>Note:</strong> Plivo offers{" "}
                  <strong>$25 in complimentary credit</strong> for{" "}
                  <strong>new Plivo Users on their Plivo account</strong> which can be
                  used to purchase a Plivo number and make <strong>outbound calls</strong>{" "}
                  using that number.
                </>
              )

          }
        </Box>

      </Grid>
      <Grid item>
        <Stepper
          orientation="vertical"
          connector={
            <StepConnector
              sx={(theme) => ({
                "& .MuiStepConnector-line": { minHeight: theme.spacing(2) },
              })}
            />
          }
        >
          {data?.steps?.map((step, index) => (
            <Step index={index} key={step} active>
              <StepLabel sx={{ p: 0 }}>
                <Typography variant="subtitle2">{step}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>



    </Grid>
  )
}

StepperUI.prototype = {
  data: PropTypes.object,
}

StepperUI.defaultProps = {
  data: {},
}

export default StepperUI
