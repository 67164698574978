import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import CloseIcon from '@mui/icons-material/Close';
import {
    CLEVERTAP_EVENT,
    EXISTING_SCRIPT_CALL_OPENING_MESSAGE,
    EXISTING_SCRIPT_GOAL,
    SCRIPT_TEMPLATES,
} from "../../../../data/configs/constants";
import ComponentWithSteps from "./GenerativeAI";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import VariableInputEditor from "../../../MentionEditor";
import { cleverTap } from "../../../../data/configs/clevertap";
import { useSelector } from "react-redux";
import { handleCleverTapBtnClick } from "../../../../data/configs/utils";
import CustomHintPopover from "../../../CustomHintPopover";
import PsychologyAltOutlinedIcon from '@mui/icons-material/PsychologyAltOutlined';


const ScriptExpandingCard = ({
    script,
    handleChange,
    isExpanded,
    handleExpandClick,
    assistant,
    isSaveClicked,
    setIsSavedClicked,
    fromOnboarding,
    mentionList
}) => {
    const user = useSelector((state) => state.authDetails.user)
    const scriptTextFieldRef = useRef(null);
    const [isOpenGenerateAssistantScript, setIsOpenGenerateAssistantScript] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState("current");
    const [isTamplateChoosen, setIsTamplateChoosen] = useState(false);
    const [template, setTemplate] = useState(script);
    const [onceCliked, setOnceCliked] = useState(false);
    const editorRef = useRef(null)
    const [isAIScriptClosed, setsetAIScriptClosed] = useState(true);
    const [createManually, setCreateManually] = useState(() => {
        const storedValue = localStorage.getItem('createManually');
        // If value is present in local storage, parse it to boolean
        return JSON.parse(storedValue);
    });


    const handleSaveChangeAndTamplateChange = (newScript) => {
        setTemplate(newScript);
        if (newScript && newScript?.length > 0)
            handleChange({ script: newScript });
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    function replaceNewlinesWithBr(inputString) {
        return inputString.replace(/\n/g, '<br/>');
    }

    const handleOpenGenerateAssistantScript = () => {
        setIsOpenGenerateAssistantScript(true);
        handleCleverTapBtnClick("Generate Assistant Script");
    }

    const handleUseExistingTamplates = () => {
        handleCleverTapBtnClick("Use Existing Templates");
        setOnceCliked(true);
        setIsOpenGenerateAssistantScript(false);
        handleSetToValue(false);
        setTemplate(SCRIPT_TEMPLATES['existing script']);
        handleChange({ script: SCRIPT_TEMPLATES['existing script'] });
        handleChange({ callOpeningMessage: EXISTING_SCRIPT_CALL_OPENING_MESSAGE });
        handleChange({ goal: EXISTING_SCRIPT_GOAL });
    }


    const handleUseGenerativeAI = () => {
        handleCleverTapBtnClick("Use Generative AI");
        setIsOpenGenerateAssistantScript(false);
        toggleModal();
        handleSetToValue(false);
    }
    const handleCreateManually = () => {
        handleCleverTapBtnClick("Create Script Manually");
        setIsOpenGenerateAssistantScript(false);
        handleSetToValue(true);
        handleChange({ script: "" });
    }

    const handleClickOpenScriptDocumentation = () => {
        window.open(process.env.REACT_APP_SCRIPT_DOCUMENTATION_URL, '_blank');
    };


    const handleSetToValue = (value) => {
        setCreateManually(value);
        localStorage.setItem('createManually', JSON.stringify(value));
    };

    useEffect(() => {
        if (editorRef.current) editorRef.current.editor.focus()
    }, [createManually])

    useEffect(() => {
        localStorage.setItem('createManually', JSON.stringify(createManually));
    }, [createManually]);

    useEffect(() => {
        if (isSaveClicked) {
            setSelectedTemplate("current");
            setIsSavedClicked(false);
        }
        setSelectedTemplate("current");
        setIsTamplateChoosen(false);
    }, [isSaveClicked, script])

    useEffect(() => {
        if (scriptTextFieldRef.current) {
            scriptTextFieldRef.current.focus();
            scriptTextFieldRef.current.setSelectionRange(0, 0);
            scriptTextFieldRef.current.scrollTop = 0;
        }
    }, [isTamplateChoosen, selectedTemplate]);


    useEffect(() => {
        if (!createManually)
            if (((script === "<p><br></p>" || script === "") && isAIScriptClosed && isSaveClicked)) {
                setIsOpenGenerateAssistantScript(true);
            }
    }, [script, isAIScriptClosed, isSaveClicked]);

    useEffect(() => {
        if (!createManually)
            if ((script === "<p><br></p>" || script === "") && isAIScriptClosed) {
                setIsOpenGenerateAssistantScript(true);
            }
    }, [script])



    return (
        <Card sx={{ width: "100%" }}>
            <CardActions disableSpacing>
                <Grid container alignItems="center" >
                    <Grid item xs={4} paddingLeft={1}>
                        <Typography display="flex" variant="h6">
                            Script
                            <CustomHintPopover
                                size="small"
                                maxWidth={400}
                                // hintTitle={'Bot Temperature Setting'}
                                transformHorizontalPosition={'left'}
                                transformVerticalPosition={'center'}
                                anchorHorizontalPosition={'right'}
                                anchorVerticalPosition={'top'}
                                hintContent={"Compose a script outlining the dialogue flow for your assistant's interactions with users. Include prompts, responses, and any relevant actions or decisions the assistant should take based on user input."}
                            />
                        </Typography>
                    </Grid>
                    <Grid xs={8} container item display='flex' justifyContent='flex-end' paddingRight={1}>
                        <Grid item align="end" mr={2}>
                            <IconButton
                                style={{ cursor: 'pointer', padding: 0 }}
                                onClick={handleClickOpenScriptDocumentation}
                            >
                                <CustomHintPopover
                                    icon={<PsychologyAltOutlinedIcon />}
                                    size="small"
                                    maxWidth={400}
                                    transformHorizontalPosition={'right'}
                                    transformVerticalPosition={'top'}
                                    anchorHorizontalPosition={'right'}
                                    anchorVerticalPosition={'bottom'}
                                    hintContent={
                                        <>
                                            <p>Seeking assistance in creating a script that works best for you ?</p><br />
                                            <p>Click to learn more !</p>
                                        </>
                                    }
                                />
                            </IconButton>
                        </Grid>
                        <Grid item align="end">
                            {
                                (((script !== "<p><br></p>") && (script !== "")) || createManually) &&
                                <Tooltip title={!assistant?.goal?.trim() ? "Assistant goal is required to Generate Script" : "Use generative AI or our proven script templates as starting point for your script "} placement="top" >
                                    <span>
                                        <Button
                                            variant={fromOnboarding ? "contained" : "outlined"}
                                            disabled={(fromOnboarding && onceCliked) || !assistant?.goal?.trim()}
                                            sx={{ paddingInline: 4 }}
                                            onClick={handleOpenGenerateAssistantScript} >
                                            Generate Assistant Script
                                        </Button>
                                    </span>
                                </Tooltip>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </CardActions>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit sx={{ paddingInline: 2, marginBottom: 2 }}>
                {(((script !== "<p><br></p>") && (script !== "")) || createManually) ? (
                    <VariableInputEditor
                        style={{
                            paddingBlock: "10px"

                        }}
                        formattedEnvVariables={mentionList}
                        placeholder="Press @ to select a variable"
                        value={(script || template) && replaceNewlinesWithBr(script ?? template)}
                        onTextChange={htmlText => handleSaveChangeAndTamplateChange(htmlText)}
                        handleChange={handleChange}
                        autoFocus
                        editorRef={editorRef}
                    wordLimit={user?.isSuperUser ? 100000 : 1000}

                    />
                ) : (
                    <Grid
                        item
                        xs={12}
                        container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight={350} // Set the desired minimum height
                    >
                        <Tooltip title={!assistant?.goal?.trim() ? "Assistant goal is required to Generate Script" : "Generate the script for your assistant"} placement="top" >
                            <span>
                                <Button variant="contained"
                                    disabled={(fromOnboarding && onceCliked) || !assistant?.goal?.trim()}
                                    sx={{ paddingInline: 4, paddingBlock: 2 }}
                                    onClick={handleOpenGenerateAssistantScript} >
                                    Generate Assistant Script
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>

                )

                }
            </Collapse>
            {/* Choose Template Dialog */}




            <Dialog open={isOpenGenerateAssistantScript} onClose={() => setIsOpenGenerateAssistantScript(false)}>
                <Grid margin={2}>
                    <DialogTitle>
                        <Typography align="center" variant="h5" fontWeight={500} mb={2}>Generate Assistant Script</Typography>

                        {/* Close button */}
                        <IconButton
                            size="small"
                            sx={{
                                zIndex: 1,
                                position: "absolute",
                                right: 12,
                                top: 12,
                                backgroundColor: "background.paper",
                                "&:hover": {
                                    backgroundColor: "background.default",
                                },
                            }}
                            onClick={() => setIsOpenGenerateAssistantScript(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography align="center" variant="body2" >
                            A script outlines the conversation between the bot and customers, guiding the bot's responses and interactions throughout the call to make sure the conversation flows as required.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Grid container>
                            <Grid container item xs={12} justifyContent="space-between" align="center" mb={2}>
                                <Grid item xs={6}>
                                    <Tooltip title="Use Existing Template to generate your assistant script">
                                        <Button sx={{ minWidth: "220px" }} onClick={handleUseExistingTamplates} variant="contained">
                                            Use Existing Template
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6}>
                                    <Tooltip title="Use Generate AI to generate your assistant script">
                                        <span>
                                            <Button sx={{ minWidth: "220px" }} disabled={fromOnboarding} onClick={handleUseGenerativeAI} variant="contained" color="primary">
                                                Use Generative AI
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} align="center" mb={2}>
                                <Typography>OR</Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent="center" align="center" mb={2}>
                                <Grid item xs={6}>
                                    <Tooltip title="Create script manually by yourself">
                                        <span>
                                            <Button sx={{ minWidth: "220px" }} disabled={fromOnboarding} onClick={handleCreateManually} variant="contained">
                                                Create Script Manually
                                            </Button>
                                        </span>

                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>



                    </DialogActions>
                </Grid>

            </Dialog>
            <ComponentWithSteps
                setsetAIScriptClosed={setsetAIScriptClosed}
                assistant={assistant}
                handleChange={handleChange}
                isModalOpen={isModalOpen}
                closeModal={toggleModal}
                setIsOpenGenerateAssistantScript={setIsOpenGenerateAssistantScript}
            />
        </Card >
    );
};

export default ScriptExpandingCard;
