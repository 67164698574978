import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Paper,
  Button,
  Box,
  Select,
  MenuItem,
  Chip,
  InputAdornment,
  IconButton,
  Tooltip,
  Card,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomHintPopover from "../CustomHintPopover";
import { useDispatch } from "react-redux";
import { isValidUrl, log } from "../../data/configs/utils";
import * as _ from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { enqueueSnackbar } from "notistack";
const BookAMeetingMsg = ({
  handleSetPayloadDataAndFormDataToInitialValue,
  setSelectedOpen,
  editMsgTemplate,
  setEditMsgTemplate,
  handleAddDeleteTriggerPrompts,
  triggerPrompts,
  formData,
  updatingAction,
  setFormData,
  handleFormDataChange,
  createAction,
}) => {
  const dispatch = useDispatch();
  const [currentTriggerPrompt, setCurrentTriggerPrompt] = useState("");
  const [errors, setErrors] = useState({
    templateName: '',
    messageBody: '',
    meetingLink: '',
    triggerPrompts: '',
  });
  const [choosenEvent, setChoosenEvent] = useState({
    eventId: "",
    meetingLocation: {},
  });
  const handleCurrentTriggerPrompt = (e) => {
    if (e.keyCode === 13) {
      handleAddDeleteTriggerPrompts("add", currentTriggerPrompt);
      setCurrentTriggerPrompt("");
      setErrors({ ...errors, triggerPrompts: '' })
    }
  };

  const errorMessage = {
    templateName: 'Please enter a template name.',
    messageBody: 'Please enter the message body.',
    meetingLink: 'Please enter the meeting link.',
    triggerPrompts: `Entered 'Triggering Prompt' not added. Click 'Add' to include it.`
  }

  const handleFormDataChangeExtention = (parent, name, value) => {
    handleFormDataChange(parent, name, value)

    if (!value?.trim().length) {
      setErrors({ ...errors, [name]: errorMessage[name] })
    }
    else {
      setErrors({ ...errors, [name]: '' })
    }
  }

  const validateForm = (data) => {
    let isError = false;
    const newErrors = { templateName: '', messageBody: '', meetingLink: '', triggerPrompts: '' };

    if (!data?.templateName?.trim().length) {
      newErrors.templateName = 'Please enter a template name.';
      isError = true;
    }

    if (!data?.communicationChannelInfo.messageBody?.trim().length) {
      newErrors.messageBody = 'Please enter the message body.';
      isError = true;
    }
    if (!data?.communicationChannelInfo.meetingLink?.trim().length) {
      newErrors.meetingLink = 'Please enter the meeting link.';
      isError = true;
    }
    if (currentTriggerPrompt?.trim().length) {
      newErrors.triggerPrompts = `'Entered 'Triggering Prompt' not added. Click 'Add' to include it.`;
      isError = true;
    }

    setErrors(newErrors);
    return isError;
  };

  const handleCreateUpdateTemplate = () => {

    if (validateForm(formData)) {
      return;
    }

    if (!isValidUrl(formData?.communicationChannelInfo?.meetingLink?.trim(), true)) {
      enqueueSnackbar(`Please enter a valid Meeting Link.`, {
        variant: "error",
      });
      return;
    }

    if (formData?.communicationChannelInfo?.meetingLink?.length >
      100 ||
      formData?.communicationChannelInfo?.messageBody?.length >
      250 ||
      formData?.templateName.trim() === '' ||
      currentTriggerPrompt?.trim()?.length > 0
    ) {
      return;
    }



    editMsgTemplate
      ? updatingAction("communication")
      : createAction("communication");
  };

  const handleSetEventData = (obj) => {
    setChoosenEvent({
      ...choosenEvent,
      ...obj,
    });
    Object.entries(obj)?.forEach(([key, value]) =>
      handleFormDataChangeExtention("communicationChannelInfo", key, value)
    );
  };

  return (
    <Paper elevation={0} sx={{ padding: 1 }}>
      <IconButton
        aria-label="close"
        onClick={() => {
          setSelectedOpen(false);
          setEditMsgTemplate(false);
          handleSetPayloadDataAndFormDataToInitialValue();
        }}
        sx={{
          position: "absolute",
          right: 16,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h6" align="center" mb={1}>
        {editMsgTemplate
          ? "Modify Your Booking Template"
          : "Set Up Your SMS Booking Template"}
      </Typography>
      <Grid
        borderLeft="4px solid #acb8cc"
        item
        mb={1}
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingBlock={1}
        marginInline={2}
        paddingInline={2}
        bgcolor="#d8e7ff"
      >
        <Typography variant="subtitle2" paragraph="none" mb={0}>
          During a call, if the user says any of the <strong>specific trigger prompts (e.g., "I'm interested in scheduling a meeting" or "Can we set up an appointment?")</strong>, an <strong>SMS</strong> will be sent using the <strong>message body and meeting link</strong> you've set up in the template. <strong>Trigger prompts can be customized to suit your needs.</strong>
        </Typography>
      </Grid>
      <Grid
        mb={3}
        borderLeft="4px solid #acb8cc"
        item
        xs={12}
        paddingBlock={1}
        marginInline={2}
        paddingInline={2}
        bgcolor="#d8e7ff"
      >
        <Typography variant="subtitle2" paragraph="none" mb={0}>
          <strong>Note:</strong> You don't need to include the meeting link in your message body. It will be automatically added to the end of your message
        </Typography>
      </Grid>
      <Box component="form" mt={6}>
        <Grid container spacing={2} item>
          <Grid
            container
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              xs={3}
              sx={{ pl: 2.5 }}
              item
              display="flex"
              alignItems="center"
            >
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle2"
                color="text.secondary"
              >
                Template Name *
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"center"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    <>
                      <p>Enter a name for your template. </p>
                      <p>This helps you identify it later.</p>
                    </>
                  }
                />
              </Typography>
            </Grid>
            <Grid xs={9} item>
              <TextField
                size="small"
                label="Template Name"
                placeholder="Enter Template Name"
                name="templateName"
                value={formData.templateName}
                onChange={(e) =>
                  handleFormDataChangeExtention("root", e.target.name, e.target.value)
                }
                fullWidth
              />
              {errors.templateName && (
                <Typography color="error" variant="caption" paddingInline={2}>
                  {errors.templateName}
                </Typography>
              )}
            </Grid>
          </Grid>
          {/* <Grid container mb={1.5} display='flex' alignItems='center' justifyContent='center'>
                        <Grid xs={3} sx={{ pl: 2.5 }} item display='flex' alignItems='center'>
                            <Typography display="flex" alignItems="center" variant="subtitle2" color="text.secondary">
                                Twilio Number
                                <CustomHintPopover
                                    size="small"
                                    maxWidth={400}
                                    transformHorizontalPosition={'left'}
                                    transformVerticalPosition={'center'}
                                    anchorHorizontalPosition={'right'}
                                    anchorVerticalPosition={'top'}
                                    hintContent={"Enter the name of your booking template."}
                                />
                            </Typography>
                        </Grid>
                        <Grid xs={9} item>
                            <Select
                                sx={{ mb: 1.5 }}
                                size="small"
                                fullWidth
                                value={formData?.calendarSpecificInfo?.attendeeTimeZone?.value || ''}
                                name="attendeeTimeZone"
                                displayEmpty
                                onChange={(e) => handleFormDataChange('calendarSpecificInfo', e.target.name, parseTimezone(e.target.value))}
                            >
                                <MenuItem value="" disabled selected>
                                    <span>Choose Time Zone</span>
                                </MenuItem>
                                {
                                    options?.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            <span>{option?.label}</span>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                    </Grid> */}
          <Grid
            container
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              xs={3}
              sx={{ pl: 2.5 }}
              item
              display="flex"
              alignItems="center"
            >
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle2"
                color="text.secondary"
              >
                Message Body *
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"center"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    "Write the text message that will be sent to the recipient"
                  }
                />
              </Typography>
            </Grid>
            <Grid xs={9} item>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderColor:
                      formData?.communicationChannelInfo?.messageBody?.length >
                        250
                        ? "#FF0000"
                        : "primary",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color:
                      formData?.communicationChannelInfo?.messageBody?.length >
                        250
                        ? "#FF0000"
                        : "primary",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor:
                      formData?.communicationChannelInfo?.messageBody
                        ?.length > 250
                        ? "#FF0000"
                        : "primary",
                  },
                }}
                size="small"
                label="Message Body"
                placeholder="Enter Message Body"
                name="messageBody"
                value={formData?.communicationChannelInfo?.messageBody}
                onChange={(e) =>
                  handleFormDataChangeExtention(
                    "communicationChannelInfo",
                    e.target.name,
                    e.target.value
                  )
                }
                fullWidth
                multiline
                rows={4}
                inputProps={{
                  maxLength: 500,
                }}
              />

              {formData?.communicationChannelInfo?.messageBody?.length >
                250 && (
                  <Typography color="error" variant="caption" paddingInline={2}>
                    Message body character limit exceeded. Please shorten your message to 250 characters or less.
                  </Typography>
                )}
              {errors.messageBody && (
                <Typography color="error" variant="caption" paddingInline={2}>
                  {errors.messageBody}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              xs={3}
              sx={{ pl: 2.5 }}
              item
              display="flex"
              alignItems="center"
            >
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle2"
                color="text.secondary"
              >
                Meeting Link *
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"center"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    "Provide a URL link that user can use to schedule a meeting or appointment."
                  }
                />
              </Typography>
            </Grid>
            <Grid xs={9} item>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderColor:
                      formData?.communicationChannelInfo?.meetingLink?.length >
                        100
                        ? "#FF0000"
                        : "primary",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color:
                      formData?.communicationChannelInfo?.meetingLink?.length >
                        100
                        ? "#FF0000"
                        : "primary",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor:
                      formData?.communicationChannelInfo?.meetingLink
                        ?.length > 100
                        ? "#FF0000"
                        : "primary",
                  },
                }}
                size="small"
                label="Meeting Link"
                placeholder="Enter Meeting Link"
                name="meetingLink"
                multiline
                value={formData?.communicationChannelInfo?.meetingLink}
                onChange={(e) =>
                  handleFormDataChangeExtention(
                    "communicationChannelInfo",
                    e.target.name,
                    e.target.value
                  )
                }
                fullWidth
              />
              {formData?.communicationChannelInfo?.meetingLink?.length >
                100 && (
                  <Typography color="error" variant="caption" paddingInline={2}>
                    Meeting link should be under 100 characters.
                  </Typography>
                )}
              {errors.meetingLink && (
                <Typography color="error" variant="caption" paddingInline={2}>
                  {errors.meetingLink}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              xs={3}
              sx={{ pl: 2.5 }}
              item
              display="flex"
              alignItems="center"
            >
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle2"
                color="text.secondary"
              >
                Trigger Prompts
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"center"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    <>
                      <p>
                        Specify phrases or cues that, when mentioned during a
                        call, will prompt the system to send an SMS using this
                        template
                      </p>
                    </>
                  }
                />
              </Typography>
            </Grid>
            <Grid xs={9} item sx={{ marginBottom: errors.triggerPrompts ? 1 : 0 }}>
              <TextField
                sx={{ marginBottom: errors.triggerPrompts ? 0 : 1 }}
                size="small"
                label="Trigger Prompts"
                placeholder="Enter Triggering Prompts"
                name="triggerPrompts"
                value={currentTriggerPrompt}
                onKeyDown={(e) => handleCurrentTriggerPrompt(e)}
                onChange={(e) => { setErrors({ ...errors, triggerPrompts: '' }); setCurrentTriggerPrompt(e.target.value) }}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="add guest"
                        onClick={() => {
                          setErrors({ ...errors, triggerPrompts: '' })
                          handleAddDeleteTriggerPrompts(
                            "add",
                            currentTriggerPrompt
                          );
                          setCurrentTriggerPrompt("");
                        }}
                        edge="end"
                        sx={{
                          paddingInline: 2,
                          backgroundColor:
                            currentTriggerPrompt?.length > 0
                              ? "secondary.main"
                              : "primary",
                          borderRadius: "24px",
                          paddingBlock: 0.7,
                        }}
                      >
                        <Typography variant="caption" fontWeight="600">
                          Add
                        </Typography>

                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.triggerPrompts && (
                <Typography color="error" variant="caption" paddingInline={2}>
                  {errors.triggerPrompts}
                </Typography>
              )}
            </Grid>
            {triggerPrompts?.length > 0 ? (
              <Grid
                container
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {triggerPrompts?.map((string) => (
                  <Grid item xs={9} key={string} sx={{ marginBottom: 1 }}>
                    <Card
                      sx={{
                        paddingInline: 2,
                        borderRadius: "24px",
                        bgcolor: "#d8e7ff",
                      }}
                    >
                      <Grid container xs={12}>
                        <Grid
                          item
                          xs={10}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ paddingInline: 1 }}
                            color="text.primary"
                          >
                            {string}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              handleAddDeleteTriggerPrompts("delete", string)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {/* <Tooltip
              arrow
              placement="top"
              title={isDisabled ? getDisabledReason() : ""}
            >
              <span> */}
            <Button
              variant="contained"
              // disabled={isDisabled}
              onClick={handleCreateUpdateTemplate}
              color="primary"
              sx={{ width: "200px" }}
            >
              <Typography textTransform="none">
                {editMsgTemplate ? "Update Template" : "Create Template"}
              </Typography>
            </Button>
            {/* </span>
            </Tooltip> */}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default BookAMeetingMsg;
