import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, Box, CardHeader, TextField, Dialog, DialogContent, keyframes, DialogActions, DialogTitle, Tooltip, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import { CLEVERTAP_EVENT, ROUTE_PATH } from '../../data/configs/constants';
import { cleverTap } from '../../data/configs/clevertap';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ErrorBoundary from '../ErrorBoundary';
import TestAssistantForm from '../TestAssistantForm';
import { useState } from 'react';
import CustomHintPopover from "../CustomHintPopover"
export default function AssistantCard({ onClick, assistant, handleEditAssistant, handleDeleteAssistant }) {
    const pop = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;
    const [dialogData, setDialogData] = useState({})
    const [dialogDataForDelete, setDialogDataForDelete] = useState(false);
    const { pathname } = useLocation()
    const workspaceId = useSelector((state) => state.authDetails?.user?.workspace)
    function strShortener(str = "") {
        if (str.length > 80) {
            return `${str.slice(0, 80)}...`;
        } else {
            return str;
        }
    }

    const handleTalkToAgentClick = (e) => {
        e.stopPropagation();
        cleverTap.track(CLEVERTAP_EVENT.BUTTON_CLICK, {
            workspaceId,
            buttonName: "Test Your Assistant",
            onboarding: pathname === ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT,
        })
        setDialogData({
            showTestAssistant: true,
            testAssistant: {},
        })
    }

    const handleCloseDialoge = (e, reason) => {
        if (reason !== "backdropClick") setDialogData({})
    }
    const handleCloseDialogeForDelete = (e) => {
        setDialogDataForDelete(false)
    }

    return (
        <>
            <Card
                onClick={onClick}
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: onClick ? 'pointer' : 'default', // Corrected the condition
                    minWidth: 100,
                    paddingBottom: 1,
                    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.2)'
                }}
            >
                <CardHeader variant="subtitle1" sx={{ borderBottom: 1, borderColor: "#d3d3d3", paddingRight: 1 }}
                    title={
                        <Grid container alignItems="center" sx={{ paddingLeft: 1, paddingRight: 1 }}>
                            <Grid item xs={10}>
                                <Typography sx={{ fontSize: '1.35rem', fontWeight: 'bold' }}>
                                    {(assistant?.name && assistant?.name !== '') ? assistant?.name : "The Bot"}
                                </Typography>
                            </Grid>
                            <Grid container item xs={2} justifyContent="space-between" alignItems="center">
                                <Grid item >
                                    <SettingsIcon onClick={(e) => {
                                        e.stopPropagation()
                                        handleEditAssistant()
                                    }} sx={{ '&:hover': { animation: `${pop} 0.3s ease-in-out forwards` } }} />
                                </Grid>
                                <Grid item>
                                    <DeleteIcon
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setDialogDataForDelete(true)
                                        }}
                                        aria-label="delete"
                                        sx={{ '&:hover': { animation: `${pop} 0.3s ease-in-out forwards` } }} />
                                </Grid>
                            </Grid>
                        </Grid>

                    }
                />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Grid container sx={{}}>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: 20, fontWeight: 'bold', paddingLeft: 1 }} color="text.primary" gutterBottom>
                                Goal
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ paddingLeft: 1 }}>{assistant?.goal ? strShortener(assistant?.goal) : <Typography variant='caption' color='main.secondary' sx={{ fontWeight: 'bold' }}>Add assistant goal</Typography>}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions sx={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                    <Grid sx={{ width: "40%" }}>
                        <Button size="medium" variant='contained' sx={{ width: "100%", backgroundColor: '#222' }}>Setup</Button>
                    </Grid>
                    <Grid sx={{ width: "40%" }}>
                        <Tooltip
                            title={!assistant.script?.trim() ? "Assistant script is required to test" : ""}
                        >
                            <span >
                                <Button size="medium" variant='contained' style={{ width: "100%" }} disabled={!assistant.script?.trim()} onClick={handleTalkToAgentClick}>
                                    <Typography variant='subtitle2' display="flex" alignItems="center">
                                        Test
                                    </Typography>
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>

                </CardActions>
            </Card>
            <Dialog
                PaperProps={{
                    sx: {
                        overflowY: 'visible'
                    },
                }}
                aria-labelledby="vg-test-assistant"
                open={
                    dialogData.showTestAssistant || dialogData.showVoiceAccent || false
                }
                onClose={handleCloseDialoge}
                fullWidth
            >
                <DialogContent sx={{ overflowY: 'visible' }}>
                    {dialogData?.showTestAssistant && (
                        <ErrorBoundary onErrorCallback={handleCloseDialoge}>
                            <TestAssistantForm
                                assistantId={assistant.assistantId}
                                onCancel={handleCloseDialoge}
                            />
                        </ErrorBoundary>
                    )}
                </DialogContent>
            </Dialog>
            <Dialog
                aria-labelledby="vg-delete-assistant"
                open={dialogDataForDelete}
                onClose={handleCloseDialogeForDelete}
                fullWidth
            >
                <DialogTitle fullWidth sx={{ justifyContent: "center" }}>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography align='center'>Do you really want to delete this <strong>({assistant?.name})</strong> assistant?</Typography>
                </DialogContent>
                <DialogActions sx={{ padding: 2, justifyContent: "center" }}>
                    <Button onClick={(e) => { e.stopPropagation(); handleCloseDialogeForDelete(); }} color="primary" variant='contained'>
                        Cancel
                    </Button>
                    <Button onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteAssistant();
                        handleCloseDialogeForDelete();
                    }} color="error" variant='contained'>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}