import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { enqueueSnackbar } from "notistack"
import { useDispatch } from "react-redux"

import { updateAuthState, getUserProfile } from "../../data/store/authSlice"
import { LOCAL_STORAGE, ROUTE_PATH } from "../../data/configs/constants"
import { setDataInLocalStorage } from "../../data/configs/utils"

import SpinnerLoader from "../SpinnerLoader"

const GoogleAuthentication = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has("token")) {
      const token = queryParams.get("token")
      const signup = queryParams.get("sign_up")
      if (signup === "true") setDataInLocalStorage(LOCAL_STORAGE.IS_NEW_USER, true)
      dispatch(updateAuthState({ token }))
      dispatch(getUserProfile())
        .unwrap()
        .then((res) => {
          navigate(ROUTE_PATH.ASSISTANTS)
          setDataInLocalStorage(LOCAL_STORAGE.AUTH_TOKEN, token)
        })
        .catch((err) => {
          dispatch(updateAuthState({ token: "" }))
          navigate(ROUTE_PATH.LOGIN)
        })
    } else {
      enqueueSnackbar("Invalid Authentication Link", { variant: "error" })
      navigate(ROUTE_PATH.LOGIN)
    }
  }, [dispatch, navigate])

  return <SpinnerLoader brand />
}

export default GoogleAuthentication
